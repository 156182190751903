.l-searchPage {
	
}

.l-searchBlc {
	padding: 12px 20px;
	background: #457fc7;
	margin-bottom: 30px;
}

.l-searchPage__title  {
	margin-bottom: 27px;
	font-family: $sans-font;
	font-size: 20px;
	color: #3e4144;
	span {
		color: #32629e;
		font-weight: bold;
	}
}

.l-searchPage__news,
.l-searchPage__cat {
	margin-bottom: 30px;
}