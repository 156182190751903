.l-calc {
	display: flex;
	&--left {
		width: 375px;
		padding-right: 15px;
	}
	&--right {
		width: calc(100% - 375px);
		padding-left: 15px;
	}
	.c-field {
		display: flex;
		margin-bottom: 10px;
	}
	.lbl {
		width: 112px;
		padding-right: 10px;
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}
	.inp {
		height: 40px;
		padding: 0 9px;
		&--center {
			text-align: center;
			padding: 0;
			width: 40px;
			flex-shrink: 0 ;
		}
	}
	.c-button {
		width: 100%;
		margin-top: 20px;
		&:focus, &:active {
			background: transparent;
			color: #316ab2;
		}
	}
	.l-shippingTable {
		margin-top: 0;
		width: 100%;
	}
	.l-shippingTable .tc1 {
		display: none;
	}
}

.c-calc-result {
	display: none;
}

#volumeCargo {
	width: calc(100% - (152px + ((40px + 9.34px + 12px) * 2)));
	text-align: right;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight: bold;
	color: #316ab2;
	line-height: 1;
	justify-content: flex-end;
}

#ULcityDELIVERY{display:none; position: absolute; background:#f1f4f7;border:1px solid #d4d9e0;border-top:none;overflow:hidden;width:248px; height:auto;}
.LIcityDELIVERY{color:#000;font-size:12px;width:auto;padding:4px;height:auto;line-height:22px;list-style:none;}
.LIcityDELIVERY:hover{background:#E4C2A2;cursor:pointer;color:#000;font-size:12px;width:auto;padding:4px; line-height:22px; list-style:none;}