.c-button {
	text-decoration: none;
	width: 262px;
	display: flex;
	height: 60px;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 2px solid #316ab2;
	background: transparent;
	border-radius: 30px;
	text-transform: uppercase;
	font-family: $sans-font;
	font-weight: bold;
	font-size: 18px;
	line-height: 1;
	white-space: nowrap;
	color: #316ab2;
	outline: none;
	&:hover, &:active, &:focus {
		background: #316ab2;
		color: #ffcd51;
	}
	&--yellow {
		border-color: #ffcd51;
		color: #f3c34b;
		background: rgba(255, 255, 255, .10);
		&:hover, &:active, &:focus {
			background: #ffcd51;
			color: #316ab2;
		}
	}
	&--center {
		margin: 0 auto;
	}
	&--300 {
		width: 299px;
	}
	&--min {
		width: 170px;
		height: 34px;
		font-size: 14px;
	}
}