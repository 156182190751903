.l-homeSlider {
	background: #e8ecf1;
}

.c-forTitle {
	background: #457fc7;
	height: 50px;
	.container {
		display: flex;
		align-items: center;
		height: 50px;
	}
}

.w-homeSlider {
	padding: 40px 0 50px;
}

body.home {
	.l-homeSeotext {
		padding-bottom: 60px;
		&:last-child {
			padding-bottom: 60px;
		}
	}
}

.l-homeSeotext {
	padding-bottom: 60px;
	.c-title {
		margin-bottom: 19px;
	}
	.c-button {
		margin-top: 33px;
	}
	&:last-child {
		padding-bottom: 0;
	}
}