body.error {
	background: url("../images/error-bg.jpg") 0 0;
	header {
		display: none;
	}
	.c-footNav {
		display: none;
	}
	.l-footSearch {
		width: 0;
		display: none;
	}
	.l-footCounters {
		width: 83.33333333%;
	}
}

.l-errorPage {
	min-height: calc(100vh - 193px);
	padding-bottom: 150px;
}

.c-error__img {
	width: 360px;
	height: 296px;
	margin: 0 auto 56px;
	background: url("../images/error-img.png") no-repeat 50% 50%;
	max-width: 100%;
	background-size: cover;
}

.c-error__title {
	text-align: center;
	color: #ffffff;
	font-family: $sans-font;
	font-weight: bold;
	margin: 0 0 15px;
	font-size: 24px;
}

.c-error__text {
	text-align: center;
	color: #ffffff;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 53px;
}