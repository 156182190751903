.l-homeExc {
	padding: 57px 0 47px;
}

.c-homeExc {
	border: 5px solid #e8ecf1;
	border-bottom: 40px solid #e8ecf1;
	position: relative;
	padding: 31px 71px 43px;
	.c-title {
		position: absolute;
		top: -14px;
		left: 52px;
		line-height: 1;
		white-space: nowrap;
		padding: 0 17px;
		background: #fff;
		margin: 0;
		span {
			color: #32629e;
			text-transform: uppercase;
		}
	}
}

.c-homeExc__items {
	display: flex;
	flex-flow: row wrap;
	margin: 0 -8px;
}

.c-homeExc__item {
	width: 25%;
	padding: 0 8px;
	text-align: center;
	margin-top: 45px;
}

.c-homeExc__img {
	margin-bottom: 18px;
}

.c-homeExc__text {
	font-size: 18px;
	font-family: $sans-font;
	color: #32629e;
	line-height: 1.2;
	padding: 0 16px;
}