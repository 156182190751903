.c-title {
	font-size: 24px;
	margin: 0;
	font-family: $sans-font;
	font-weight: bold;
	&--white {
		color: #fff;
	}
	&--icon {
		height: 50px;
		display: flex;
		align-items: center;
		position: relative;
		padding-left: 71px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 50px;
			height: 50px;
			background-color: #ffcd51;
			background-image: url("../images/tag-icon.png");
			background-repeat: no-repeat;
			background-position: 50% 50%;
		}
	}
	&--blc {
		position: absolute;
		top: -14px;
		left: 52px;
		line-height: 1;
		white-space: nowrap;
		padding: 0 17px;
		background: #fff;
		margin: 0;
	}
}