.navN {
	ul {
		list-style-type: none;
		display: flex;
		margin: 0;
	}
	li {
		margin-right: 7px;
		&:last-child {
			margin-right: 0;
		}
		&.on {
			a {
				text-decoration: none;
				color: #ffffff;
				border-color: #457fc7;
				background-color: #457fc7;
			}
		}
	}
	a {
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: #e8ecf1;
		border: 1px solid #d4d9e0;
		text-decoration: none;
		font-size: 16px;
		color: #3e4144;
		font-family: $sans-font;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #ffffff;
			border-color: #457fc7;
			background-color: #457fc7;
		}
	}
	.navN-first,
	.navN-prev,
	.navN-next,
	.navN-last {
		a {
			background-image: url("../images/navn.png");
			&:hover, &:active, &:focus {
				border-color: #d4d9e0;
				background-color: #e8ecf1;
				background-position-x: -34px;
			}
		}
	}
	.navN-first {
		a {
			background-position-y: -102px;
		}
	}
	.navN-prev {
		a {
			background-position-y: -34px;
		}
	}
	.navN-last {
		a {
			background-position-y: -68px;
		}
	}
}