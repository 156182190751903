* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body, html {
  height: 100%; }

body {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #3e4144;
  line-height: 1.2; }

a {
  color: #316ab2;
  text-decoration: underline; }
  a:hover, a:active, a:focus {
    text-decoration: none;
    color: #6199df; }

ol, ul {
  list-style-position: inside; }

img {
  max-width: 100%;
  height: auto; }

p {
  line-height: 24px; }
  p:last-child {
    margin-bottom: 0; }

body.home .hm {
  height: 469px;
  padding-bottom: 0; }

header {
  padding-top: 50px; }

.ht {
  background: #457fc7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }

.c-dropMenu__link {
  background: #316ab2;
  position: relative; }
  .c-dropMenu__link a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    color: #ffcd51;
    text-decoration: none;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-family: "PT Sans", Arial, sans-serif;
    font-weight: bold;
    line-height: 1;
    white-space: nowrap; }
  .c-dropMenu__link:hover {
    background: #1c5eb1; }
    .c-dropMenu__link:hover > a {
      color: #ffdc51; }
    .c-dropMenu__link:hover .w-dropMenu {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }

.c-dropMenu__list {
  width: 263px; }

.w-dropMenu {
  z-index: 3;
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  min-width: 263px; }
  .w-dropMenu ul {
    background: #1c5eb1;
    padding-left: 18px;
    list-style-type: none;
    margin: 0; }
    .w-dropMenu ul li {
      border: 1px solid #d4d9e0;
      border-top: none;
      border-left: none; }
      .w-dropMenu ul li:first-child {
        border-top: 1px solid #d4d9e0; }
      .w-dropMenu ul li:last-child {
        border-bottom: none; }
      .w-dropMenu ul li:hover a, .w-dropMenu ul li:active a, .w-dropMenu ul li:focus a, .w-dropMenu ul li.active a {
        background: #dde2e9;
        color: #32629e; }
    .w-dropMenu ul a {
      display: block;
      white-space: normal;
      padding: 12px 15px 12px;
      background: #e8ecf1;
      font-size: 16px;
      color: #32629e;
      text-align: left;
      height: auto; }

.l-navMenu ul {
  list-style-type: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  margin: 0; }
  .l-navMenu ul li.active a {
    font-weight: bold;
    color: #ffcd51; }
  .l-navMenu ul a {
    text-decoration: none;
    color: #ffffff;
    font-family: "PT Sans", Arial, sans-serif;
    line-height: 1;
    white-space: nowrap; }
    .l-navMenu ul a:hover, .l-navMenu ul a:active, .l-navMenu ul a:focus {
      color: #f4c03e;
      text-decoration: none; }

.c-headAuth__link {
  background: #316ab2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  position: relative; }
  .c-headAuth__link a {
    font-family: "PT Sans", Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #ffcd51;
    text-transform: uppercase;
    text-decoration: none; }
    .c-headAuth__link a:hover, .c-headAuth__link a:active, .c-headAuth__link a:focus {
      color: #ffdc51;
      text-decoration: none; }
  .c-headAuth__link--false a:first-child {
    padding-left: 80px; }
    .c-headAuth__link--false a:first-child:before {
      position: absolute;
      content: '';
      display: block;
      width: 54px;
      height: 50px;
      top: 0;
      left: 0;
      background-color: #ffcd51;
      background-image: url("../images/auth-icon.png");
      background-repeat: no-repeat;
      background-position: 50% 50%; }
    .c-headAuth__link--false a:first-child:hover:before {
      background-color: #ffdc51; }
  .c-headAuth__link--true {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center; }
    .c-headAuth__link--true a {
      padding-left: 54px; }
      .c-headAuth__link--true a:before {
        position: absolute;
        content: '';
        display: block;
        width: 54px;
        height: 50px;
        top: 0;
        left: 0;
        background-color: #ffcd51;
        background-image: url("../images/exit-icon.png");
        background-repeat: no-repeat;
        background-position: 50% 50%; }
      .c-headAuth__link--true a:hover:before {
        background-color: #ffdc51; }

.c-headAuth__sep {
  margin: 0 12px 0 15px;
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  color: #ffcd51; }

.hm {
  position: relative;
  padding-bottom: 35px;
  background: url("../images/hm-bg.jpg") no-repeat 50% 50%;
  background-size: cover; }
  .hm > .container {
    position: relative;
    z-index: 2; }

.l-hm__left {
  padding-top: 25px; }

.l-hm__top {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px; }

.c-logo {
  display: block;
  margin-right: 30px; }

.c-hm__phones {
  padding-left: 30px;
  border-left: 3px solid #fbc02d;
  color: #fefefe;
  font-family: "PT Sans", Arial, sans-serif;
  font-size: 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 337px; }
  .c-hm__phones > div {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }

.c-hm__mail {
  padding-left: 37px;
  text-decoration: none;
  color: #fefefe;
  font-family: "PT Sans", Arial, sans-serif;
  font-size: 16px;
  position: relative;
  line-height: 1;
  white-space: nowrap; }
  .c-hm__mail:hover, .c-hm__mail:active, .c-hm__mail:focus {
    color: #ffcd51;
    text-decoration: none; }
  .c-hm__mail:before {
    position: absolute;
    content: '';
    display: block;
    width: 23px;
    height: 16px;
    background: url("../images/mail-icon.png") no-repeat 0 0;
    left: 0;
    top: calc(50% - 7px); }

.w-headCart {
  background: #316ab2;
  padding: 29px 20px 24px; }

.c-headCart {
  border: 2px solid #f9ca54;
  padding: 30px 20px 25px 30px;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 99px; }

.c-headCart__title {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  color: #f3c34b;
  font-weight: bold;
  position: absolute;
  display: block;
  padding: 0 10px;
  top: -9px;
  left: 28px;
  background: #316ab2;
  line-height: 1;
  white-space: nowrap; }
  .c-headCart__title:hover, .c-headCart__title:active, .c-headCart__title:focus {
    text-decoration: none;
    color: #ffdc51; }

.c-headCart__icon {
  display: block;
  margin-right: 15px;
  width: 38px;
  height: 35px;
  background: url("../images/cart-icon.png") no-repeat 0 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }
  .c-headCart__icon:hover {
    background-position: 0 -35px; }

.c-headCart__info {
  color: #fefefe;
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  line-height: 20px;
  min-height: 40px; }

.l-hm__bottom {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  padding: 0 34px;
  border-radius: 9px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  font-size: 16px;
  color: #ffffff;
  font-family: "PT Sans", Arial, sans-serif;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.c-tooltip {
  position: absolute;
  bottom: -38px;
  left: calc(50% - 43px);
  background: #457fc7;
  display: none;
  width: 86px;
  height: 30px;
  font-family: "PT Sans", Arial, sans-serif;
  font-size: 14px;
  color: #ffcd51;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }
  .c-tooltip:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 6px 8px;
    border-color: transparent transparent #457fc7 transparent;
    position: absolute;
    top: -6px;
    left: calc(50% - 8px); }

.c-hm__addressIcon {
  position: relative;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }
  .c-hm__addressIcon:hover .c-tooltip {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }

.c-hm__officeAddress {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 27px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .c-hm__officeAddress .c-hm__addressIcon {
    margin-right: 20px;
    width: 25px;
    height: 24px;
    display: block;
    font-style: normal;
    background: url("../images/office-icon.png") no-repeat 0 0; }

.c-hm__skladAddress {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .c-hm__skladAddress .c-hm__addressIcon {
    margin-right: 20px;
    width: 40px;
    height: 22px;
    display: block;
    font-style: normal;
    background: url("../images/sklad-icon.png") no-repeat 0 0; }

.hb {
  background: #457fc7;
  height: 64px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.c-headSearch form {
  height: 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.c-headSearch input[type="text"] {
  width: 848px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0 30px;
  margin-right: 3px; }
  .c-headSearch input[type="text"]::-webkit-input-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: italic;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]::-moz-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: italic;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]:-moz-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: italic;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]:-ms-input-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 16px;
    font-style: italic;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .c-headSearch input[type="text"]:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }

.c-headSearch input[type="submit"] {
  width: 67px;
  height: 40px;
  border: none;
  background-color: #ffcd51;
  background-image: url("../images/search-icon.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  outline: none; }
  .c-headSearch input[type="submit"]:hover {
    background-color: #ffdc51; }

.c-adp__button {
  font-family: "PT Sans", Arial, sans-serif;
  display: none;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  background: #316ab2;
  font-weight: bold;
  color: #ffcd51;
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
  z-index: 10; }
  .c-adp__button > span {
    position: relative;
    padding-left: 25px;
    line-height: 1;
    white-space: nowrap; }
  .c-adp__button .nav-icon {
    width: 15px;
    height: 13px;
    position: absolute;
    left: 0;
    top: calc(50% - 5.5px);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer; }
    .c-adp__button .nav-icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #ffcd51;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: .25s ease-in-out; }
      .c-adp__button .nav-icon span:nth-child(1) {
        top: 0px; }
      .c-adp__button .nav-icon span:nth-child(2) {
        top: 5px; }
      .c-adp__button .nav-icon span:nth-child(3) {
        top: 10px; }
    .c-adp__button .nav-icon.open span:nth-child(1) {
      top: 5px;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg); }
    .c-adp__button .nav-icon.open span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .c-adp__button .nav-icon.open span:nth-child(3) {
      top: 5px;
      -webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg); }

.c-adpCart {
  display: none;
  background: #457fc7;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 14px 0; }
  .c-adpCart .c-icon {
    width: 27px;
    height: 25px;
    display: block;
    background: url("../images/cart-icon.png") no-repeat 0 0;
    background-size: cover;
    margin-right: 18px; }
  .c-adpCart .c-headCart__info span:first-child {
    margin-right: 12px; }

body.home .l-slider {
  display: block; }

.l-slider {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

.slick-slider *:focus {
  outline: none; }

.c-headSlider {
  height: 469px;
  overflow: hidden; }
  .c-headSlider .c-slide {
    padding-top: 218px;
    height: 469px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat; }
    .c-headSlider .c-slide > .container {
      position: relative;
      z-index: 3; }
    .c-headSlider .c-slide .row {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .c-headSlider .c-slide:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 469px;
      background: rgba(0, 0, 0, 0.25); }
  .c-headSlider .c-slide__title {
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 0 24px; }
  .c-headSlider .c-slide__text {
    font-family: Arial, sans-serif;
    color: #fff;
    line-height: 24px;
    font-size: 16px;
    padding-right: 10px; }
  .c-headSlider .c-slide__button {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .c-headSlider .dots-container {
    position: relative;
    top: -72px; }
  .c-headSlider .slick-dots {
    margin: 0;
    list-style-type: none;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .c-headSlider .slick-dots li {
      margin-right: 14px; }
      .c-headSlider .slick-dots li:last-child {
        margin-right: 0; }
      .c-headSlider .slick-dots li.slick-active button {
        background: #ffcd51; }
    .c-headSlider .slick-dots button {
      border: none;
      outline: none;
      line-height: 0;
      font-size: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
      .c-headSlider .slick-dots button:hover {
        background: #ffcd51; }

.c-homeSlider {
  width: 1006px;
  margin: 0 auto; }
  .c-homeSlider .slick-list {
    margin: 0 -5px; }
  .c-homeSlider .c-slide {
    margin: 0 5px;
    position: relative;
    border-radius: 9px;
    overflow: hidden; }
    .c-homeSlider .c-slide:hover .c-slide__top {
      border-color: #457fc7; }
    .c-homeSlider .c-slide--new .c-slide__top {
      border-top-right-radius: 11px; }
    .c-homeSlider .c-slide--new:after {
      content: '';
      width: 69px;
      height: 69px;
      display: block;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 3;
      background: url("../images/new-tooltip.png") no-repeat 0 0; }
    .c-homeSlider .c-slide--sale .c-slide__top {
      border-top-right-radius: 11px; }
    .c-homeSlider .c-slide--sale:after {
      content: '';
      width: 69px;
      height: 69px;
      display: block;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 3;
      background: url("../images/sale-tooltip.png") no-repeat 0 0; }
  .c-homeSlider .c-slide__top {
    background: #ffffff;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border: 1px solid #d4d9e0;
    overflow: hidden;
    border-bottom: none; }
    .c-homeSlider .c-slide__top hr {
      width: 152px;
      margin: 0 auto 14px;
      border-color: #d4d9e0; }
  .c-homeSlider .c-slide__img {
    height: 199px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background: #fff; }
  .c-homeSlider .c-slide__name {
    height: 51px;
    text-align: center;
    padding: 0 15px; }
    .c-homeSlider .c-slide__name a {
      font-family: "PT Sans", Arial, sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #32629e;
      text-decoration: none;
      line-height: 14px;
      display: inline-block; }
      .c-homeSlider .c-slide__name a:hover, .c-homeSlider .c-slide__name a:active, .c-homeSlider .c-slide__name a:focus {
        text-decoration: none;
        color: #457fc7; }
  .c-homeSlider .c-slide__price {
    text-align: center;
    height: 53px;
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 14px;
    line-height: 1;
    white-space: nowrap; }
    .c-homeSlider .c-slide__price--pre {
      color: #787e84;
      text-decoration: line-through; }
    .c-homeSlider .c-slide__price--cur {
      color: #3e4144;
      padding-top: 9px; }
      .c-homeSlider .c-slide__price--cur b {
        font-size: 20px; }
  .c-homeSlider .c-slide__price--pre + .c-slide__price--cur {
    padding-top: 3px; }
  .c-homeSlider .c-slide__button {
    height: 50px;
    border: none;
    outline: none;
    background: #316ab2;
    width: 100%;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    overflow: hidden; }
    .c-homeSlider .c-slide__button span {
      position: relative;
      font-family: "PT Sans", Arial, sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: #fff;
      padding-left: 57px;
      text-transform: uppercase; }
      .c-homeSlider .c-slide__button span:before {
        content: '';
        display: block;
        width: 39px;
        height: 25px;
        position: absolute;
        left: 0;
        top: calc(50% - 12.5px);
        background: url("../images/button-cart-icon.png") no-repeat 0 0; }
    .c-homeSlider .c-slide__button:hover {
      background: #457fc7; }
      .c-homeSlider .c-slide__button:hover span:before {
        background-position: 0 -25px; }
  .c-homeSlider .slick-arrow {
    width: 39px;
    height: 60px;
    border: none;
    outline: none;
    background: url("../images/slider-arrow.png") no-repeat 0 0;
    position: absolute;
    top: calc(50% - 30px); }
    .c-homeSlider .slick-arrow:hover {
      background-position-y: 0; }
  .c-homeSlider .slick-prev {
    left: -67px;
    background-position-y: -60px; }
  .c-homeSlider .slick-next {
    right: -67px;
    background-position-x: -39px;
    background-position-y: -60px; }

.c-button {
  text-decoration: none;
  width: 262px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border: 2px solid #316ab2;
  background: transparent;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  color: #316ab2;
  outline: none; }
  .c-button:hover, .c-button:active, .c-button:focus {
    background: #316ab2;
    color: #ffcd51; }
  .c-button--yellow {
    border-color: #ffcd51;
    color: #f3c34b;
    background: rgba(255, 255, 255, 0.1); }
    .c-button--yellow:hover, .c-button--yellow:active, .c-button--yellow:focus {
      background: #ffcd51;
      color: #316ab2; }
  .c-button--center {
    margin: 0 auto; }
  .c-button--300 {
    width: 299px; }
  .c-button--min {
    width: 170px;
    height: 34px;
    font-size: 14px; }

.c-searchSelect {
  width: 222px;
  height: 40px;
  visibility: hidden; }
  .c-searchSelect + .select2 * {
    outline: none !important; }
  .c-searchSelect + .select2 .select2-selection {
    height: 40px;
    border-radius: 0;
    border: none; }
    .c-searchSelect + .select2 .select2-selection .select2-selection__rendered {
      height: 40px;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: "PT Sans", Arial, sans-serif;
      font-size: 16px;
      font-style: italic;
      color: #505356;
      padding: 0 45px 0 20px; }
    .c-searchSelect + .select2 .select2-selection .select2-selection__arrow {
      height: 40px;
      top: 0;
      right: 0;
      width: 45px; }
      .c-searchSelect + .select2 .select2-selection .select2-selection__arrow b {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: #999999 transparent transparent transparent;
        margin-left: -6px;
        margin-top: -3px; }
      .c-searchSelect + .select2 .select2-selection .select2-selection__arrow:hover b {
        border-color: #555759 transparent transparent transparent; }

.select2-container .select2-dropdown {
  background-color: #f7f9fb;
  border: 1px solid #c3c7cd;
  border-radius: 0;
  border-top: none;
  padding: 12px 0; }
  .select2-container .select2-dropdown .select2-results__option {
    padding: 7px 10px 7px 19px;
    font-family: "PT Sans", Arial, sans-serif;
    font-size: 14px;
    color: #3e4144; }
  .select2-container .select2-dropdown .select2-results__option[aria-selected=true],
  .select2-container .select2-dropdown .select2-results__option--highlighted {
    background: #dce2ea; }

.c-customSelect {
  width: 100%;
  height: 40px;
  visibility: hidden; }
  .c-customSelect + .select2 * {
    outline: none !important; }
  .c-customSelect + .select2 .select2-selection {
    height: 40px;
    border-radius: 0;
    border: 1px solid #d4d9e0;
    background: #f1f4f7; }
    .c-customSelect + .select2 .select2-selection .select2-selection__rendered {
      height: 40px;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: "PT Sans", Arial, sans-serif;
      font-size: 14px;
      color: #505356;
      padding: 0 45px 0 20px; }
    .c-customSelect + .select2 .select2-selection .select2-selection__arrow {
      height: 40px;
      top: 0;
      right: 0;
      width: 45px; }
      .c-customSelect + .select2 .select2-selection .select2-selection__arrow b {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: #999999 transparent transparent transparent;
        margin-left: -6px;
        margin-top: -3px; }
      .c-customSelect + .select2 .select2-selection .select2-selection__arrow:hover b {
        border-color: #555759 transparent transparent transparent; }

.pcheck__row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.pcheck__item {
  margin-right: 28px; }

.c-customRadio {
  position: absolute;
  visibility: hidden; }
  .c-customRadio + label {
    font-size: 14px;
    color: #57595d;
    margin-bottom: 0;
    position: relative;
    padding-left: 31px;
    cursor: pointer;
    font-weight: normal; }
    .c-customRadio + label:before {
      position: absolute;
      left: 0;
      top: calc(50% - 8px);
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background: transparent;
      border-radius: 50%;
      border: 1px solid rgba(180, 184, 191, 0.95); }
  .c-customRadio:checked + label:after {
    position: absolute;
    top: calc(50% - 4px);
    left: 4px;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: #316ab2;
    border-radius: 50%; }

.l-homeCatalog {
  background: url("../images/cat-bg.png") 0 0;
  padding: 30px 0; }

.c-catList {
  margin: 0 -5px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.l-catItem {
  width: 20%;
  padding: 0 5px;
  margin-bottom: 10px; }

.c-catItem {
  border: 1px solid #d4d9e0;
  background: #fff;
  border-radius: 9px;
  overflow: hidden; }
  .c-catItem:hover .w-catItem--main {
    display: none; }
  .c-catItem:hover .w-catItem--hover {
    display: block; }

.c-catItem__img {
  height: 236px;
  border-bottom: 1px solid #d4d9e0;
  background: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background: #fff; }

.c-catItem__name {
  height: 80px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background: #e8ecf1;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  color: #32629e;
  font-weight: bold;
  line-height: 1.2; }

.w-catItem--main {
  display: block; }

.w-catItem--hover {
  display: none; }
  .w-catItem--hover .c-catItem__name {
    height: 69px; }

.c-catItem__links {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 166px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;
  background: #e8ecf1;
  overflow: hidden; }
  .c-catItem__links a {
    font-size: 14px;
    font-family: "PT Sans", Arial, sans-serif;
    text-decoration: underline;
    color: #3e4144;
    margin-bottom: 13px; }
    .c-catItem__links a:last-child {
      margin-bottom: 0; }
    .c-catItem__links a:hover, .c-catItem__links a:active, .c-catItem__links a:focus {
      color: #457fc7;
      text-decoration: none; }

.c-catItem__all {
  height: 81px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #e8ecf1; }
  .c-catItem__all a {
    color: #32629e;
    font-family: "PT Sans", Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline; }
    .c-catItem__all a:hover, .c-catItem__all a:active, .c-catItem__all a:focus {
      text-decoration: none;
      color: #457fc7; }

.l-catPage {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -15px;
  font-family: "PT Sans", Arial, sans-serif;
  margin-bottom: 17px; }

.l-catPage__item {
  width: 50%;
  padding: 0 15px;
  margin-bottom: 25px; }

.c-catPage__name {
  display: inline-block;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #32629e;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none; }
  .c-catPage__name span {
    color: #3e4144; }
  .c-catPage__name:hover, .c-catPage__name:active, .c-catPage__name:focus {
    text-decoration: none;
    color: #457fc7; }
    .c-catPage__name:hover span, .c-catPage__name:active span, .c-catPage__name:focus span {
      color: #457fc7; }

.i-catPage__item {
  position: relative;
  height: 214px; }

.w-catPage__item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background: #457fc7;
  padding-bottom: 5px;
  border-radius: 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  overflow: hidden; }

.c-catPage__item {
  background: #ffffff;
  border: 1px solid #d4d9e0;
  border-radius: 9px;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 28px 25px 24px 10px;
  height: 209px;
  min-height: 209px; }
  .c-catPage__item:hover {
    height: auto; }

.c-catPage__img {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 10px; }

.c-catPage__linkList {
  position: relative;
  top: -0.25em; }
  .c-catPage__linkList ul {
    list-style-type: none;
    margin: 0; }
    .c-catPage__linkList ul li {
      margin-bottom: 7px; }
      .c-catPage__linkList ul li:last-child {
        margin-bottom: 0; }
    .c-catPage__linkList ul a {
      padding-left: 17px;
      position: relative;
      font-size: 14px;
      color: #3e4144;
      line-height: 1.2;
      display: inline-block; }
      .c-catPage__linkList ul a:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 6px;
        width: 6px;
        height: 6px;
        background: #316ab2;
        border-radius: 50%; }
      .c-catPage__linkList ul a:hover, .c-catPage__linkList ul a:active, .c-catPage__linkList ul a:focus {
        text-decoration: none;
        color: #457fc7; }
        .c-catPage__linkList ul a:hover:before, .c-catPage__linkList ul a:active:before, .c-catPage__linkList ul a:focus:before {
          background: #457fc7; }

.l-catPageCategory {
  padding: 0 15px;
  width: 25%;
  text-align: center;
  margin-bottom: 30px; }

.w-catPageCategory__img {
  border-radius: 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  overflow: hidden;
  background: #457fc7;
  padding-bottom: 5px;
  margin-bottom: 16px; }

.c-catPageCategory__img {
  border: 1px solid #d4d9e0;
  border-radius: 9px;
  overflow: hidden;
  padding: 8px 0 7px;
  background: #ffffff;
  text-align: center; }

.c-catPageCategory__name {
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  color: #32629e;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 7px;
  text-decoration: none; }

.c-catPageCategory__art {
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  color: #63676b; }

.c-product__top {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.l-product__gallery {
  width: 40.709%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 472px;
  overflow: hidden; }
  .l-product__gallery--min {
    height: auto; }

.l-productInfo {
  width: 59.291%;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }
  .l-productInfo .c-pin {
    padding-left: 30px; }

.w-product__gallery {
  padding-bottom: 5px;
  background: #457fc7;
  border-radius: 10px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  overflow: hidden;
  margin-bottom: 18px;
  height: auto; }
  .w-product__gallery--nomg {
    margin-bottom: 0; }

.c-product__gallery .c-slide {
  border: 1px solid #d4d9e0;
  border-radius: 9px;
  overflow: hidden;
  background: #ffffff;
  height: 379px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.c-product__galleryNav {
  width: 282px;
  margin: 0 auto; }
  .c-product__galleryNav .slick-arrow {
    width: 20px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    background: url("../images/slider-nav--prod.png") no-repeat 0 0;
    border: none;
    outline: none; }
    .c-product__galleryNav .slick-arrow:hover {
      background-position-x: -20px; }
  .c-product__galleryNav .slick-prev {
    left: -32px; }
  .c-product__galleryNav .slick-next {
    right: -32px;
    background-position-y: -30px; }
  .c-product__galleryNav .c-slide {
    cursor: pointer;
    margin: 0 2px; }

.c-productInfo {
  padding: 24px 30px 25px 40px;
  margin-left: -10px;
  margin-bottom: 25px;
  background: url("../images/prod-info--bg.png");
  font-family: "PT Sans", Arial, sans-serif;
  color: #fff;
  font-size: 16px; }
  .c-productInfo div {
    margin-bottom: 11px; }
    .c-productInfo div:last-child {
      margin-bottom: 0; }
    .c-productInfo div b {
      text-transform: uppercase;
      margin-right: 9px; }
    .c-productInfo div a {
      color: #fecf5b;
      text-decoration: underline; }
      .c-productInfo div a:hover, .c-productInfo div a:active, .c-productInfo div a:focus {
        text-decoration: none;
        color: #fecf5b; }

.c-product__top {
  padding-bottom: 40px;
  border-bottom: 5px solid #e8ecf1;
  margin-bottom: 30px; }
  .c-product__top--noborder {
    border-bottom: none;
    padding-bottom: 0; }
  .c-product__top--min {
    margin-bottom: 24px; }

.c-product__bottom > .c-pin {
  margin-bottom: 30px; }

.c-productTable {
  font-family: "PT Sans", Arial, sans-serif; }
  .c-productTable--mg {
    margin-bottom: 30px; }
  .c-productTable table {
    border-collapse: separate;
    border-spacing: 0 2px;
    table-layout: fixed; }
  .c-productTable thead th {
    text-align: center;
    height: 57px;
    background: #457fc7;
    font-size: 13px;
    font-weight: normal;
    color: #ffffff; }
  .c-productTable tbody tr:hover > td:not(.tc10) {
    background: #fffaed; }
  .c-productTable tbody tr.new td:first-child a {
    position: relative;
    height: 51px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    width: 39px; }
    .c-productTable tbody tr.new td:first-child a:before {
      content: '';
      width: 34px;
      height: 34px;
      display: block;
      background: url("../images/new-min.png") no-repeat 0 0;
      position: absolute;
      left: 0;
      top: 0; }
  .c-productTable tbody tr.sale td:first-child a {
    position: relative;
    height: 51px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center; }
    .c-productTable tbody tr.sale td:first-child a:before {
      content: '';
      width: 34px;
      height: 34px;
      display: block;
      background: url("../images/sale-min.png") no-repeat 0 0;
      position: absolute;
      left: 0;
      top: 0; }
  .c-productTable tbody td {
    border: 1px solid #d4d9e0;
    border-left: none;
    text-align: center;
    font-size: 13px; }
    .c-productTable tbody td:first-child {
      border-left: 1px solid #d4d9e0; }
    .c-productTable tbody td.tc1 a {
      display: block; }
    .c-productTable tbody td.tc2 {
      width: 83px; }
    .c-productTable tbody td.tc9 {
      width: 90px; }
    .c-productTable tbody td.tc10 {
      background: #f1f4f7;
      width: 58px; }
      .c-productTable tbody td.tc10 input {
        background: transparent;
        width: 100%;
        height: 49px;
        border: none;
        outline: none;
        text-align: center;
        color: #8a8b93;
        padding-left: 10px;
        font-family: "PT Sans", Arial, sans-serif;
        font-size: 14px; }
        .c-productTable tbody td.tc10 input:focus {
          color: #3e4144; }
    .c-productTable tbody td.tc11 {
      width: 40px; }
      .c-productTable tbody td.tc11 button {
        border: none;
        outline: none;
        width: 40px;
        display: block;
        height: 51px;
        background-color: #457fc7;
        background: url("../images/tocart.png") no-repeat 0 0; }
        .c-productTable tbody td.tc11 button:hover {
          background-color: #ffcd51;
          background-position: 0 -51px; }
  .c-productTable .tc1 {
    width: 41px; }
    .c-productTable .tc1 img {
      width: 100%; }
  .c-productTable .tc2 {
    width: 83px;
    text-align: left;
    padding: 0 22px 0 14px; }
  .c-productTable .tc3 {
    width: 46px; }
  .c-productTable .tc4 {
    width: 103px; }
  .c-productTable .tc5 {
    width: 129px; }
  .c-productTable .tc6 {
    width: 124px; }
  .c-productTable .tc7 {
    width: 88px; }
  .c-productTable .tc8 {
    width: 43px; }
  .c-productTable .tc9 {
    width: 86px; }
  .c-productTable .tc10 {
    width: 58px; }
  .c-productTable .cart-foot {
    margin-top: 30px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }

.c-lastUpd {
  font-family: "PT Sans", Arial, sans-serif;
  color: #81878d;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap; }

.i-product__gallery {
  border: 1px solid #d4d9e0;
  border-radius: 9px;
  overflow: hidden;
  height: auto;
  position: relative;
  z-index: 10; }

.l-homeSlider {
  background: #e8ecf1; }

.c-forTitle {
  background: #457fc7;
  height: 50px; }
  .c-forTitle .container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px; }

.w-homeSlider {
  padding: 40px 0 50px; }

body.home .l-homeSeotext {
  padding-bottom: 60px; }
  body.home .l-homeSeotext:last-child {
    padding-bottom: 60px; }

.l-homeSeotext {
  padding-bottom: 60px; }
  .l-homeSeotext .c-title {
    margin-bottom: 19px; }
  .l-homeSeotext .c-button {
    margin-top: 33px; }
  .l-homeSeotext:last-child {
    padding-bottom: 0; }

.c-title {
  font-size: 24px;
  margin: 0;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold; }
  .c-title--white {
    color: #fff; }
  .c-title--icon {
    height: 50px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding-left: 71px; }
    .c-title--icon:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background-color: #ffcd51;
      background-image: url("../images/tag-icon.png");
      background-repeat: no-repeat;
      background-position: 50% 50%; }
  .c-title--blc {
    position: absolute;
    top: -14px;
    left: 52px;
    line-height: 1;
    white-space: nowrap;
    padding: 0 17px;
    background: #fff;
    margin: 0; }

.l-homeExc {
  padding: 57px 0 47px; }

.c-homeExc {
  border: 5px solid #e8ecf1;
  border-bottom: 40px solid #e8ecf1;
  position: relative;
  padding: 31px 71px 43px; }
  .c-homeExc .c-title {
    position: absolute;
    top: -14px;
    left: 52px;
    line-height: 1;
    white-space: nowrap;
    padding: 0 17px;
    background: #fff;
    margin: 0; }
    .c-homeExc .c-title span {
      color: #32629e;
      text-transform: uppercase; }

.c-homeExc__items {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 -8px; }

.c-homeExc__item {
  width: 25%;
  padding: 0 8px;
  text-align: center;
  margin-top: 45px; }

.c-homeExc__img {
  margin-bottom: 18px; }

.c-homeExc__text {
  font-size: 18px;
  font-family: "PT Sans", Arial, sans-serif;
  color: #32629e;
  line-height: 1.2;
  padding: 0 16px; }

footer {
  background: #e8ecf1; }

#up {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 243px;
  margin-left: 1175px;
  background-color: #dce2ea;
  cursor: pointer;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }
  #up:before {
    width: 31px;
    color: #505356;
    height: 20px;
    display: block;
    content: '';
    background-image: url("../images/up-icon.png");
    background-repeat: no-repeat;
    background-position: 0 0; }
  #up:hover:before {
    background-position: 0 -20px; }

.c-footNav {
  background: #316ab2; }
  .c-footNav ul {
    list-style-type: none;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 50px;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0; }
  .c-footNav li.active a {
    font-weight: bold;
    color: #ffcd51; }
  .c-footNav a {
    font-size: 16px;
    font-family: "PT Sans", Arial, sans-serif;
    color: #fff;
    text-decoration: none; }
    .c-footNav a:hover, .c-footNav a:active, .c-footNav a:focus {
      text-decoration: none;
      color: #ffcd51; }

.fm {
  padding: 28px 0 34px; }

.c-copy {
  font-size: 14px;
  color: #3e4144; }
  .c-copy p {
    line-height: 1.2; }

.l-footAddress {
  font-size: 14px;
  color: #3e4144; }
  .l-footAddress > div {
    margin-bottom: 13px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .l-footAddress > div span {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 68px; }
    .l-footAddress > div:last-child {
      margin-bottom: 0; }

.l-footContacts {
  font-size: 14px;
  color: #3e4144; }
  .l-footContacts > div {
    margin-bottom: 13px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .l-footContacts > div span {
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 68px; }
    .l-footContacts > div:last-child {
      margin-bottom: 0; }

.fb {
  padding-bottom: 30px; }

.l-footSearch form {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.l-footSearch input[type="text"] {
  width: calc(100% - 69px);
  height: 40px;
  background: #fff;
  border: 1px solid #c3c7cd;
  border-right: none;
  outline: none;
  padding: 0 18px;
  font-size: 16px;
  color: #505356; }
  .l-footSearch input[type="text"]::-webkit-input-placeholder {
    color: #505356;
    font-family: "PT Sans", Arial, sans-serif;
    font-style: italic;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]::-moz-placeholder {
    color: #505356;
    font-family: "PT Sans", Arial, sans-serif;
    font-style: italic;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]:-moz-placeholder {
    color: #505356;
    font-family: "PT Sans", Arial, sans-serif;
    font-style: italic;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]:-ms-input-placeholder {
    color: #505356;
    font-family: "PT Sans", Arial, sans-serif;
    font-style: italic;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .l-footSearch input[type="text"]:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }

.l-footSearch input[type="submit"] {
  width: 69px;
  border: none;
  outline: none;
  height: 40px;
  background-color: #316ab2;
  background-image: url("../images/search-icon--yellow.png");
  background-position: 50% 50%;
  background-repeat: no-repeat; }
  .l-footSearch input[type="submit"]:hover {
    background-color: #6199df; }

.l-footDevelop {
  text-align: right; }
  .l-footDevelop a {
    display: inline-block;
    text-decoration: none;
    color: #3e4144;
    font-size: 14px;
    font-family: "PT Sans", Arial, sans-serif;
    text-align: left; }

.l-pageContent {
  min-height: calc(100vh - 544px);
  padding-bottom: 60px; }

.c-page-title {
  margin: 0 0 30px;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  color: #3e4144;
  font-size: 24px;
  position: relative; }
  .c-page-title--link {
    margin: 0 0 18px; }
    .c-page-title--link a:not(.for-print) {
      margin-left: 11px;
      width: 17px;
      height: 27px;
      display: inline-block;
      background: url("../images/arrow-back.png") no-repeat 0 0;
      vertical-align: middle;
      margin-bottom: 3px; }
      .c-page-title--link a:not(.for-print):hover {
        background-position: 0 -27px; }
  .c-page-title .for-print {
    position: absolute;
    right: 0;
    top: 5px;
    color: #32629e;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; }
    .c-page-title .for-print:hover, .c-page-title .for-print:active, .c-page-title .for-print:focus {
      text-decoration: none;
      color: #457fc7; }
      .c-page-title .for-print:hover span:before, .c-page-title .for-print:active span:before, .c-page-title .for-print:focus span:before {
        background-position: 0 -30px; }
    .c-page-title .for-print span {
      position: relative;
      padding-left: 38px;
      line-height: 1;
      white-space: nowrap; }
      .c-page-title .for-print span:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: calc(50% - 15px);
        width: 27px;
        height: 30px;
        background: url("../images/print-icon.png") no-repeat 0 0; }

.c-breadcrumbs {
  padding: 26px 0;
  font-family: "PT Sans", Arial, sans-serif;
  color: #3e4144;
  font-size: 16px; }
  .c-breadcrumbs a {
    text-decoration: none;
    color: #3e4144; }
    .c-breadcrumbs a:hover, .c-breadcrumbs a:active, .c-breadcrumbs a:focus {
      color: #316ab2;
      text-decoration: none; }
  .c-breadcrumbs .sep {
    width: 4px;
    height: 7px;
    display: inline-block;
    background: url("../images/bc-sep.png") no-repeat 0 0;
    margin: 0 14px; }
  .c-breadcrumbs span:last-child {
    color: #81878d; }

.l-sidebar {
  z-index: 101; }

.c-sidebarNav ul {
  margin: 0;
  list-style-type: none; }

.c-sidebarNav li {
  position: relative; }
  .c-sidebarNav li.active > a {
    color: #ffcd51;
    background: #457fc7;
    border-color: #457fc7; }
  .c-sidebarNav li:last-child a {
    margin-bottom: 0; }
  .c-sidebarNav li:hover > a {
    color: #ffcd51;
    background: #457fc7;
    border-color: #457fc7; }

.c-sidebarNav a {
  padding: 11px 15px 10px 33px;
  display: block;
  background: #e8ecf1;
  color: #32629e;
  font-size: 16px;
  font-family: "PT Sans", Arial, sans-serif;
  border: 1px solid #d4d9e0;
  border-left: none;
  text-decoration: none;
  margin-bottom: 2px;
  position: relative; }
  .c-sidebarNav a:before {
    content: '';
    height: calc(100% + 2px);
    width: 18px;
    display: block;
    background: #457fc7;
    position: absolute;
    left: 0;
    top: -1px; }
  .c-sidebarNav a:hover, .c-sidebarNav a:active, .c-sidebarNav a:focus {
    color: #32629e;
    text-decoration: none; }

.c-sidebarNav > ul ul {
  background: transparent;
  width: 248px;
  padding-left: 2px;
  z-index: 100; }

.c-sidebarNav > ul > li:hover > ul {
  display: block; }

.c-sidebarNav > ul > li > ul {
  display: none;
  position: absolute;
  left: 262.5px;
  top: 0; }
  .c-sidebarNav > ul > li > ul li a {
    padding-left: 23px; }
    .c-sidebarNav > ul > li > ul li a:before {
      width: 8px; }
  .c-sidebarNav > ul > li > ul > li:hover > ul {
    display: block; }
  .c-sidebarNav > ul > li > ul > li > ul {
    display: none;
    position: absolute;
    left: 246px;
    top: 0; }
    .c-sidebarNav > ul > li > ul > li > ul > li:hover > ul {
      display: block; }
    .c-sidebarNav > ul > li > ul > li > ul > li > ul {
      display: none;
      position: absolute;
      left: 246px;
      top: 0; }

.c-sidebarNavAdp {
  display: none;
  position: relative;
  margin-bottom: 30px;
  max-width: 290px; }

.c-sidebarNavAdp__button {
  width: 290px;
  height: 42px;
  border: 1px solid #d4d9e0;
  border-left: none;
  padding-left: 38px;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  color: #32629e;
  outline: none;
  text-align: left; }
  .c-sidebarNavAdp__button:before {
    content: '';
    display: block;
    width: 18px;
    height: 42px;
    position: absolute;
    left: 0px;
    top: -1px;
    background: #457fc7; }
  .c-sidebarNavAdp__button:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 12px;
    top: calc(50% - 6px);
    right: 19px;
    background: url("../images/sidebar-adp.png") no-repeat 0 0; }

.w-sidebarNavAdp {
  display: none;
  position: absolute;
  top: 42px;
  z-index: 1000;
  left: 0;
  width: 100%; }
  .w-sidebarNavAdp ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #d4d9e0;
    border-top: none;
    border-left: 18px solid #457fc7;
    background: #e8ecf1;
    position: relative; }
    .w-sidebarNavAdp ul:before {
      content: '';
      display: block;
      position: absolute;
      width: 18px;
      background: #457fc7;
      left: -18px;
      height: 2px;
      top: -1px; }
  .w-sidebarNavAdp li:last-child a {
    border-bottom: none; }
  .w-sidebarNavAdp a {
    padding: 10px 33px 10px 15px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #d4d9e0;
    font-size: 16px;
    text-decoration: none;
    color: #32629e; }
    .w-sidebarNavAdp a:hover, .w-sidebarNavAdp a:active, .w-sidebarNavAdp a:focus {
      text-decoration: none;
      color: #32629e; }

body.error {
  background: url("../images/error-bg.jpg") 0 0; }
  body.error header {
    display: none; }
  body.error .c-footNav {
    display: none; }
  body.error .l-footSearch {
    width: 0;
    display: none; }
  body.error .l-footCounters {
    width: 83.33333333%; }

.l-errorPage {
  min-height: calc(100vh - 193px);
  padding-bottom: 150px; }

.c-error__img {
  width: 360px;
  height: 296px;
  margin: 0 auto 56px;
  background: url("../images/error-img.png") no-repeat 50% 50%;
  max-width: 100%;
  background-size: cover; }

.c-error__title {
  text-align: center;
  color: #ffffff;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  margin: 0 0 15px;
  font-size: 24px; }

.c-error__text {
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 53px; }

.c-cartTable {
  margin-top: 30px;
  margin-bottom: 37px; }
  .c-cartTable--nomg {
    margin: 0; }
  .c-cartTable table {
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
    border-spacing: 0 2px; }
    .c-cartTable table thead {
      background: #457fc7;
      color: #ffffff; }
      .c-cartTable table thead th {
        font-size: 14px;
        font-family: "PT Sans", Arial, sans-serif;
        height: 57px;
        font-weight: normal;
        text-align: center; }
        .c-cartTable table thead th.tc3 {
          text-align: left;
          padding-left: 20px; }
    .c-cartTable table tbody tr:hover > td:not(.tc7) {
      background: #fffaed; }
    .c-cartTable table tbody td {
      border: 1px solid #d4d9e0;
      border-right: none;
      background: #ffffff; }
      .c-cartTable table tbody td.tc1 {
        text-align: center;
        font-size: 14px;
        font-family: "PT Sans", Arial, sans-serif;
        color: #6b6c72; }
      .c-cartTable table tbody td.tc2 {
        text-align: center; }
      .c-cartTable table tbody td.tc3 {
        padding: 0 20px; }
        .c-cartTable table tbody td.tc3 a {
          font-family: "PT Sans", Arial, sans-serif;
          color: #457fc7;
          font-size: 14px;
          text-decoration: underline; }
          .c-cartTable table tbody td.tc3 a:hover, .c-cartTable table tbody td.tc3 a:active, .c-cartTable table tbody td.tc3 a:focus {
            text-decoration: none; }
      .c-cartTable table tbody td.tc4, .c-cartTable table tbody td.tc5, .c-cartTable table tbody td.tc6, .c-cartTable table tbody td.tc8 {
        text-align: center;
        font-size: 14px;
        color: #6b6c72;
        font-family: "PT Sans", Arial, sans-serif; }
      .c-cartTable table tbody td.tc7 {
        padding: 0;
        background: #f1f4f7; }
        .c-cartTable table tbody td.tc7 input {
          background: transparent;
          width: 100%;
          height: 49px;
          border: none;
          outline: none;
          text-align: center;
          color: #8a8b93;
          padding-left: 10px;
          font-family: "PT Sans", Arial, sans-serif;
          font-size: 14px; }
          .c-cartTable table tbody td.tc7 input:focus {
            color: #3e4144; }
      .c-cartTable table tbody td.tc9 {
        height: 51px; }
  .c-cartTable .c-cartDel {
    border: none;
    outline: none;
    width: 40px;
    display: block;
    height: 51px;
    background-color: #457fc7;
    background: url("../images/cart-del.png") no-repeat 0 0; }
    .c-cartTable .c-cartDel:hover {
      background-color: #ffcd51;
      background-position: 0 -51px; }
  .c-cartTable .tc1 {
    width: 38px; }
  .c-cartTable .tc2 {
    width: 39px; }
  .c-cartTable .tc3 {
    width: 165px; }
  .c-cartTable .tc4 {
    width: 135px; }
  .c-cartTable .tc5 {
    width: 98px; }
  .c-cartTable .tc6 {
    width: 113px; }
  .c-cartTable .tc7 {
    width: 98px; }
  .c-cartTable .tc8 {
    width: 113px; }
  .c-cartTable .tc9 {
    width: 40px; }

.c-cartTable__foot {
  margin-top: 8px;
  background: #e8ecf1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  position: relative; }
  .c-cartTable__foot--right {
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }

.c-cartTable__foot-links a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "PT Sans", Arial, sans-serif;
  color: #316ab2;
  text-decoration: none; }
  .c-cartTable__foot-links a:hover, .c-cartTable__foot-links a:active, .c-cartTable__foot-links a:focus {
    text-decoration: none;
    color: #6199df; }
  .c-cartTable__foot-links a:first-child {
    padding-left: 70px; }
    .c-cartTable__foot-links a:first-child:before {
      content: '';
      display: block;
      width: 50px;
      height: 50px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #457fc7;
      background-image: url("../images/backlink.png");
      background-repeat: no-repeat;
      background-position: 50% 50%; }
    .c-cartTable__foot-links a:first-child:hover:before {
      background-color: #6199df; }

.c-cartTable__foot-links .sep {
  margin: 0 7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-family: "PT Sans", Arial, sans-serif;
  color: #316ab2; }

.c-cartTable__foot-sum {
  line-height: 1;
  white-space: nowrap;
  font-family: "PT Sans", Arial, sans-serif;
  color: #316ab2;
  font-weight: bold;
  font-size: 16px;
  padding-right: 40px; }
  .c-cartTable__foot-sum span {
    font-size: 14px;
    font-weight: normal;
    margin-left: 6px; }

.c-cartBlc {
  border: 5px solid #e8ecf1;
  border-bottom: 40px solid #e8ecf1;
  position: relative;
  padding: 31px 29px 39px;
  margin-bottom: 37px; }
  .c-cartBlc:last-child {
    margin-bottom: 0; }
  .c-cartBlc--mg {
    margin-top: 27px; }
  .c-cartBlc--shipping .field {
    width: 423px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
    .c-cartBlc--shipping .field .lbl {
      width: 63px;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
  .c-cartBlc--shipping .pcheck {
    margin-top: 25px; }
    .c-cartBlc--shipping .pcheck .lbl {
      margin-bottom: 14px; }
  .c-cartBlc--contens .field {
    width: 423px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px; }
    .c-cartBlc--contens .field .lbl {
      width: 157px;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      padding-right: 12px; }
    .c-cartBlc--contens .field:last-child {
      margin-bottom: 0; }
  .c-cartBlc form + p {
    margin-top: 20px;
    color: #316ab2; }

.c-cartBlc__text {
  font-size: 14px;
  color: #3e4144;
  margin-bottom: 27px; }
  .c-cartBlc__text span {
    color: #316ab2; }

.l-shippingTable {
  margin-top: 29px;
  display: none;
  width: 505px;
  font-family: "PT Sans", Arial, sans-serif; }
  .l-shippingTable.on {
    display: block; }
  .l-shippingTable table {
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;
    table-layout: fixed; }
  .l-shippingTable thead th {
    background: #e8ecf1;
    height: 51px;
    font-size: 16px;
    font-weight: normal;
    color: #3e4144;
    line-height: 18px; }
    .l-shippingTable thead th.tc2 {
      padding-left: 20px; }
  .l-shippingTable tbody tr td {
    background: #f1f4f7;
    font-size: 16px;
    color: #3e4144;
    line-height: 18px; }
  .l-shippingTable tbody tr:nth-child(even) td {
    background: #e8ecf1; }
  .l-shippingTable .tc1 {
    width: 45px;
    text-align: center; }
    .l-shippingTable .tc1 label {
      padding-left: 16px;
      height: 16px; }
  .l-shippingTable .tc2 {
    width: 273px;
    padding: 10px 20px 10px 20px; }
  .l-shippingTable .tc3 {
    width: 77px;
    text-align: center; }
  .l-shippingTable .tc4 {
    width: 104px;
    text-align: center; }
  .l-shippingTable .w-shippingTable__name {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .l-shippingTable .c-shippingTable__img {
    width: 70px;
    background-size: contain;
    background-position: 50% 50%;
    height: 70px;
    text-align: center;
    margin-right: 20px;
    background-repeat: no-repeat; }
  .l-shippingTable .c-shippingTable__name {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }

.c-cartBlc__top {
  padding: 0 29px;
  padding-bottom: 30px;
  border-bottom: 5px solid #e8ecf1;
  margin: 0 -29px; }

.c-cartBlc__bottom {
  padding: 0 29px;
  padding-top: 30px;
  margin: 0 -29px; }
  .c-cartBlc__bottom--nopd {
    padding-top: 0; }

.l-cart #BlockInputTrKom {
  margin-top: 20px; }

.l-cart .BlockInputSityTrKom {
  margin: 0;
  float: none; }

.l-cart .l-shippingTable {
  margin-top: 20px; }

.l-cart #deliveryResultBlock {
  margin: 0;
  width: 505px; }

.l-cart #ULcityDELIVERY {
  width: 360px;
  z-index: 100; }

.l-cart .LIcityDELIVERY {
  padding: 4px 19px; }
  .l-cart .LIcityDELIVERY:hover {
    background: #dce2ea;
    padding: 4px 19px; }

.l-cart .selectedCity {
  padding: 4px 19px;
  background: #dce2ea; }

.orderBlock {
  margin-top: 30px; }

.form-cart-bottom {
  margin-top: -35px;
  margin-bottom: 30px;
  background: #e8ecf1;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  position: relative;
  padding-right: 40px;
  line-height: 1;
  white-space: nowrap;
  font-weight: bold;
  color: #316ab2;
  font-size: 16px; }
  .form-cart-bottom span {
    font-weight: normal;
    margin-left: 5px; }

.lbl {
  font-size: 14px;
  color: #316ab2;
  font-weight: bold;
  font-family: "PT Sans", Arial, sans-serif;
  margin-bottom: 0; }

.inp {
  width: 100%;
  background: #f1f4f7;
  border: 1px solid #d4d9e0;
  height: 40px;
  outline: none;
  padding: 0 19px;
  font-size: 14px;
  color: #8a8b93; }
  .inp:focus {
    color: #3e4144; }
  .inp::-webkit-input-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp::-moz-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp:-moz-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp:-ms-input-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .inp:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .inp:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .inp:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }

.fieldarea {
  -webkit-align-items: flex-start !important;
  -ms-flex-align: start !important;
  -ms-grid-row-align: flex-start !important;
  align-items: flex-start !important; }
  .fieldarea .lbl {
    margin-top: 13px; }

.field {
  position: relative; }

.help-block {
  position: absolute;
  color: #de413b;
  font-size: 14px;
  margin: 0;
  width: 100%;
  left: calc(100% + 10px);
  max-width: 326px;
  font-family: "PT Sans", Arial, sans-serif; }

.inp2 {
  width: 100%;
  background: #f1f4f7;
  border: 1px solid #d4d9e0;
  height: 100px;
  outline: none;
  padding: 10px 19px;
  font-size: 14px;
  color: #8a8b93;
  resize: none; }
  .inp2:focus {
    color: #3e4144; }
  .inp2::-webkit-input-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp2::-moz-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp2:-moz-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp2:-ms-input-placeholder {
    font-family: "PT Sans", Arial, sans-serif;
    color: #505356;
    opacity: 1;
    transition: opacity 0.3s ease; }
  .inp2:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .inp2:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .inp2:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }
  .inp2:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.3s ease; }

.captcha {
  margin: 20px 0;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }
  .captcha .help-block {
    left: 314px; }

.c-lkNav ul {
  list-style-type: none;
  margin: 0; }

.c-lkNav li:first-child a {
  border-top: 1px solid #d4d9e0; }

.c-lkNav li.on a {
  background: #457fc7;
  border-color: #457fc7;
  color: #ffcd51; }

.c-lkNav a {
  display: block;
  text-decoration: none;
  background: #e8ecf1;
  border: 1px solid #d4d9e0;
  text-align: center;
  color: #32629e;
  font-size: 16px;
  font-family: "PT Sans", Arial, sans-serif;
  padding: 11px 10px 10px;
  border-top: none; }
  .c-lkNav a:hover, .c-lkNav a:active, .c-lkNav a:focus {
    text-decoration: none;
    background: #dde2e9; }

.c-lkBlc--settings .field {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 423px; }
  .c-lkBlc--settings .field:last-child {
    margin-bottom: 0; }

.c-lkBlc--settings .lbl {
  width: 153px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.c-lkBlc--settings .forbut {
  margin-top: 30px; }

.c-lkBlc--settings .help-block {
  max-width: 174px;
  line-height: 1; }

.c-lkBlc__title {
  margin: 0 0 27px;
  font-size: 18px;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  color: #3e4144; }

.c-historyTable table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0 2px;
  width: 100%;
  text-align: center;
  font-family: "PT Sans", Arial, sans-serif;
  font-size: 14px; }
  .c-historyTable table thead th {
    height: 57px;
    background: #457fc7;
    font-weight: normal;
    color: #fff;
    text-align: center; }
  .c-historyTable table tbody tr:hover > td:not(.tc5) {
    background: #fffaed; }
  .c-historyTable table tbody td {
    border: 1px solid #d4d9e0;
    border-left: none;
    height: 51px;
    color: #3e4144; }
    .c-historyTable table tbody td:first-child {
      border-left: 1px solid #d4d9e0; }
      .c-historyTable table tbody td:first-child a {
        font-weight: bold;
        color: #316ab2; }
        .c-historyTable table tbody td:first-child a:hover, .c-historyTable table tbody td:first-child a:active, .c-historyTable table tbody td:first-child a:focus {
          text-decoration: none;
          color: #6199df; }
    .c-historyTable table tbody td.tc5 {
      background: #457fc7;
      border-color: #457fc7;
      padding: 10px 14px; }
  .c-historyTable table .tc1 {
    width: 95px; }
  .c-historyTable table .tc2 {
    width: 144px; }
  .c-historyTable table .tc3 {
    width: 118px; }
  .c-historyTable table .tc4 {
    width: 132px; }
  .c-historyTable table .tc5 {
    width: 160px; }

.c-historyTable__button {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #ffcd51;
  text-decoration: none;
  border: 2px solid #ffcd51;
  border-radius: 17px;
  height: 34px;
  text-transform: uppercase;
  font-weight: bold; }
  .c-historyTable__button:hover, .c-historyTable__button:active, .c-historyTable__button:focus {
    text-decoration: none;
    color: #ffdc51;
    border-color: #ffdc51; }

.c-history__info {
  margin-top: 27px;
  margin-bottom: 25px;
  color: #3e4144;
  font-size: 16px; }
  .c-history__info div {
    margin-bottom: 10px; }
  .c-history__info .blueText {
    margin-top: 23px;
    margin-bottom: 0;
    font-weight: bold;
    color: #316ab2;
    font-size: 18px; }

.c-news__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 30px;
  border-bottom: 5px solid #e8ecf1;
  margin-bottom: 30px; }

.c-news__img {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 30px; }
  .c-news__img a {
    display: block;
    background: #316ab2;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none; }
  .c-news__img img {
    border-radius: 10px;
    overflow: hidden; }

.c-news__date {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #ffcd51;
  font-weight: bold;
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif; }
  .c-news__date span {
    position: relative;
    padding-left: 32px;
    line-height: 1;
    white-space: nowrap; }
    .c-news__date span:before {
      content: '';
      position: absolute;
      display: block;
      width: 18px;
      height: 19px;
      background: url("../images/news-date.png") no-repeat 0 0;
      left: 0;
      top: calc(50% - 9.5px); }

.c-news__right {
  position: relative;
  top: -0.25em; }

.c-news__title {
  font-family: "PT Sans", Arial, sans-serif;
  text-decoration: none;
  color: #32629e;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 9px;
  display: inline-block; }
  .c-news__title:hover, .c-news__title:active, .c-news__title:focus {
    text-decoration: none;
    color: #6199df; }

.c-news__anons {
  font-size: 14px;
  color: #3e4144;
  margin-bottom: 31px;
  line-height: 24px; }

.c-newsPage__img {
  padding-bottom: 5px;
  background: #457fc7;
  border-radius: 9px;
  overflow: hidden;
  float: left;
  margin: 0 19px 20px 0; }
  .c-newsPage__img img {
    border-radius: 9px;
    overflow: hidden; }

.c-newsPage__date {
  margin-bottom: 17px;
  font-size: 14px;
  font-family: "PT Sans", Arial, sans-serif;
  color: #316ab2;
  font-weight: bold; }
  .c-newsPage__date span {
    position: relative;
    padding-left: 32px; }
    .c-newsPage__date span:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      top: calc(50% - 9.5px);
      background: url("../images/date-icon--blue.png") no-repeat 0 0; }

.navN ul {
  list-style-type: none;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0; }

.navN li {
  margin-right: 7px; }
  .navN li:last-child {
    margin-right: 0; }
  .navN li.on a {
    text-decoration: none;
    color: #ffffff;
    border-color: #457fc7;
    background-color: #457fc7; }

.navN a {
  width: 36px;
  height: 36px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: #e8ecf1;
  border: 1px solid #d4d9e0;
  text-decoration: none;
  font-size: 16px;
  color: #3e4144;
  font-family: "PT Sans", Arial, sans-serif; }
  .navN a:hover, .navN a:active, .navN a:focus {
    text-decoration: none;
    color: #ffffff;
    border-color: #457fc7;
    background-color: #457fc7; }

.navN .navN-first a,
.navN .navN-prev a,
.navN .navN-next a,
.navN .navN-last a {
  background-image: url("../images/navn.png"); }
  .navN .navN-first a:hover, .navN .navN-first a:active, .navN .navN-first a:focus,
  .navN .navN-prev a:hover,
  .navN .navN-prev a:active,
  .navN .navN-prev a:focus,
  .navN .navN-next a:hover,
  .navN .navN-next a:active,
  .navN .navN-next a:focus,
  .navN .navN-last a:hover,
  .navN .navN-last a:active,
  .navN .navN-last a:focus {
    border-color: #d4d9e0;
    background-color: #e8ecf1;
    background-position-x: -34px; }

.navN .navN-first a {
  background-position-y: -102px; }

.navN .navN-prev a {
  background-position-y: -34px; }

.navN .navN-last a {
  background-position-y: -68px; }

.l-tabs {
  margin-bottom: 24px; }

.c-tabs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  list-style-type: none;
  font-family: "PT Sans", Arial, sans-serif;
  margin: 0; }
  .c-tabs li {
    margin-right: 5px;
    padding: 9.5px 23px;
    background: #457fc7;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    color: #f2f8ff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    border: 1px solid #457fc7;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 52px; }
    .c-tabs li:last-child {
      margin-right: 0; }
    .c-tabs li:hover {
      background: #6199df;
      border-color: #6199df; }
    .c-tabs li.on {
      padding: 14px 23px;
      min-height: 61px;
      background: #e8ecf1;
      color: #457fc7;
      border-color: #d4d9e0;
      position: relative; }
      .c-tabs li.on:before {
        content: '';
        display: block;
        height: 1px;
        background: #e8ecf1;
        width: 100%;
        position: absolute;
        bottom: -1px;
        left: 0; }

.c-tabs__content {
  display: none;
  border: 1px solid #d4d9e0;
  background: #e8ecf1;
  padding: 34px 38px 40px; }
  .c-tabs__content.on {
    display: block; }

.l-tabsAdp {
  display: none; }
  .l-tabsAdp ul {
    list-style-type: none;
    padding: 0; }
  .l-tabsAdp li {
    margin-bottom: 2px; }
    .l-tabsAdp li:first-child .c-tabsAdp__title {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden; }
    .l-tabsAdp li:last-child {
      margin-bottom: 0; }
      .l-tabsAdp li:last-child .c-tabsAdp__title {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden; }
      .l-tabsAdp li:last-child.on .c-tabsAdp__title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden; }
      .l-tabsAdp li:last-child.on .c-tabsAdp__content {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden; }
    .l-tabsAdp li.on .c-tabsAdp__title {
      border-color: #d4d9e0;
      background: #e8ecf1;
      color: #457fc7; }
    .l-tabsAdp li.on .c-tabsAdp__content {
      display: block; }

.c-tabsAdp__content {
  display: none;
  background: #e8ecf1;
  border: 1px solid #d4d9e0;
  border-top: none;
  padding: 17px 30px 43px; }

.c-tabsAdp__title {
  height: 56px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background: #457fc7;
  color: #f2f8ff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: bold;
  border: 1px solid transparent;
  border-bottom: none; }

.c-filter {
  font-family: "PT Sans", Arial, sans-serif;
  margin-top: 10px; }

.c-filter__title {
  margin-bottom: 2px;
  height: 72px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  background: url("../images/filter-bg.png");
  text-transform: uppercase; }

.c-filterButtons {
  background: #ffcd51;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  height: 50px;
  margin-bottom: 2px; }
  .c-filterButtons:hover {
    background: #ffdc51; }
  .c-filterButtons:last-child {
    margin-bottom: 0; }
  .c-filterButtons a, .c-filterButtons input {
    color: #316ab2;
    text-decoration: none;
    text-transform: uppercase;
    background: transparent;
    border: none;
    outline: none; }
    .c-filterButtons a:first-child, .c-filterButtons input:first-child {
      padding-left: 27px;
      position: relative; }
      .c-filterButtons a:first-child:before, .c-filterButtons input:first-child:before {
        content: '';
        display: block;
        width: 13px;
        height: 18px;
        position: absolute;
        left: 0;
        top: calc(50% - 9px);
        background: url("../images/filter-clean.png") no-repeat 0 0; }
    .c-filterButtons a:hover, .c-filterButtons input:hover {
      text-decoration: none;
      color: #457fc7; }
      .c-filterButtons a:hover:before, .c-filterButtons input:hover:before {
        background-position: 0 -18px; }
  .c-filterButtons .sep {
    margin: 0 8px;
    font-weight: bold;
    color: #316ab2; }

.c-filterBlock {
  margin-bottom: 2px;
  background: url("../images/filter-bg.png");
  position: relative;
  padding: 20px 14.5px 30px 20px;
  border-right: 5px solid #457fc7; }
  .c-filterBlock.on .c-filterBlock__content {
    display: block; }
  .c-filterBlock.on .c-filter__open {
    top: 0;
    bottom: initial; }
    .c-filterBlock.on .c-filter__open:before {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg); }
  .c-filterBlock .c-customSelect + .select2 {
    width: 100% !important; }
    .c-filterBlock .c-customSelect + .select2 .select2-selection {
      height: 30px; }
      .c-filterBlock .c-customSelect + .select2 .select2-selection .select2-selection__rendered {
        height: 30px; }
      .c-filterBlock .c-customSelect + .select2 .select2-selection .select2-selection__arrow {
        height: 30px; }

.c-filter__open {
  position: absolute;
  bottom: 0;
  top: initial;
  right: -5px;
  border: none;
  outline: none;
  width: 30px;
  height: 30px;
  background: #457fc7; }
  .c-filter__open:before {
    content: '';
    display: block;
    width: 15px;
    height: 9px;
    background: url("../images/filter-arrow.png") no-repeat 0 0;
    margin: 0 auto; }
  .c-filter__open:hover {
    background: #6199df; }

.c-filterBlock__label {
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold; }
  .c-filterBlock__label:hover {
    color: #ffdc51; }

.c-filterBlock__content {
  display: none;
  margin-top: 20px; }

.c-sliderRange__amount {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .c-sliderRange__amount > div {
    width: 103px; }
  .c-sliderRange__amount label {
    color: #ebeef4;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1;
    white-space: nowrap;
    font-weight: normal;
    margin-right: 10px; }
  .c-sliderRange__amount input {
    width: 77px;
    height: 20px;
    text-align: center;
    outline: none;
    background: #f1f4f7;
    border: 1px solid #d4d9e0;
    padding-left: 10px;
    color: #8a8b93; }

.c-sliderRange__min {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.c-sliderRange {
  height: 5px;
  border-radius: 0;
  border: none; }
  .c-sliderRange .ui-slider-range {
    border-radius: 0;
    background-color: #ffcd51; }
  .c-sliderRange .ui-state-focus {
    outline: none; }
  .c-sliderRange .ui-slider-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
    background: #ffcd51;
    top: calc(50% - 8px); }

.l-searchBlc {
  padding: 12px 20px;
  background: #457fc7;
  margin-bottom: 30px; }

.l-searchPage__title {
  margin-bottom: 27px;
  font-family: "PT Sans", Arial, sans-serif;
  font-size: 20px;
  color: #3e4144; }
  .l-searchPage__title span {
    color: #32629e;
    font-weight: bold; }

.l-searchPage__news,
.l-searchPage__cat {
  margin-bottom: 30px; }

.box-modal {
  background: #ffffff;
  padding: 50px 40px 40px;
  width: 440px;
  position: relative;
  font-family: "PT Sans", Arial, sans-serif; }
  .box-modal .c-pin {
    margin-top: -10px;
    margin-bottom: 30px; }
  .box-modal#pas .field--inp {
    margin-bottom: 30px; }
  .box-modal .help-block {
    position: absolute;
    color: #de413b;
    font-size: 14px;
    margin: 0;
    width: 100%;
    right: 0;
    top: -17px;
    max-width: 100%;
    left: initial;
    text-align: right; }

.box-modal_close {
  width: 16px;
  height: 18px;
  display: block;
  position: absolute;
  top: 25px;
  right: 25px;
  background: url("../images/modal-close.png") no-repeat 0 0;
  cursor: pointer; }
  .box-modal_close:hover {
    background-position: 0 -18px; }

.c-modal__title {
  margin: 0 0 30px;
  font-size: 24px;
  font-weight: bold;
  color: #302f2f; }

.field--inp {
  margin-bottom: 20px;
  position: relative; }

.field--modal {
  margin: 30px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.forbut input, .forbut button {
  outline: none; }

.c-customCheckbox {
  position: absolute;
  visibility: hidden; }
  .c-customCheckbox + label {
    font-weight: normal;
    margin-bottom: 0;
    position: relative;
    padding-left: 33px;
    font-size: 14px;
    color: #3e4144;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer; }
    .c-customCheckbox + label:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      border: 1px solid #b8bcc2;
      position: absolute;
      left: 0;
      top: calc(50% - 9px); }
  .c-customCheckbox:checked + label:after {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
    background: url("../images/c-check.png") no-repeat 0 0; }

blockquote {
  color: #3e4144;
  font-size: 14px;
  line-height: 24px;
  font-family: Arial, sans-serif;
  background: #f1f4f7;
  border-left: none;
  padding: 24px 53px 34px;
  font-style: italic; }

.pintable {
  border-collapse: separate;
  border-spacing: 0 2px; }
  .pintable th {
    background: #457fc7;
    color: #ffffff;
    padding: 10px 0;
    font-weight: normal; }
  .pintable td {
    border: 1px solid #d4d9e0;
    padding: 10px 0;
    border-left: none; }
    .pintable td:first-child {
      border-left: 1px solid #d4d9e0; }

.c-pin + .l-news {
  margin-top: 40px; }

.l-calc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .l-calc--left {
    width: 375px;
    padding-right: 15px; }
  .l-calc--right {
    width: calc(100% - 375px);
    padding-left: 15px; }
  .l-calc .c-field {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px; }
  .l-calc .lbl {
    width: 112px;
    padding-right: 10px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
  .l-calc .inp {
    height: 40px;
    padding: 0 9px; }
    .l-calc .inp--center {
      text-align: center;
      padding: 0;
      width: 40px;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0; }
  .l-calc .c-button {
    width: 100%;
    margin-top: 20px; }
    .l-calc .c-button:focus, .l-calc .c-button:active {
      background: transparent;
      color: #316ab2; }
  .l-calc .l-shippingTable {
    margin-top: 0;
    width: 100%; }
  .l-calc .l-shippingTable .tc1 {
    display: none; }

.c-calc-result {
  display: none; }

#volumeCargo {
  width: calc(100% - (152px + ((40px + 9.34px + 12px) * 2)));
  text-align: right;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #316ab2;
  line-height: 1;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

#ULcityDELIVERY {
  display: none;
  position: absolute;
  background: #f1f4f7;
  border: 1px solid #d4d9e0;
  border-top: none;
  overflow: hidden;
  width: 248px;
  height: auto; }

.LIcityDELIVERY {
  color: #000;
  font-size: 12px;
  width: auto;
  padding: 4px;
  height: auto;
  line-height: 22px;
  list-style: none; }

.LIcityDELIVERY:hover {
  background: #E4C2A2;
  cursor: pointer;
  color: #000;
  font-size: 12px;
  width: auto;
  padding: 4px;
  line-height: 22px;
  list-style: none; }

@media all and (min-width: 320px) and (max-width: 767px) {
  .l-dropMenu {
    display: none; }
  .l-navMenu {
    padding: 0; }
    .l-navMenu nav {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 10;
      width: 100%;
      background: #457fc7; }
    .l-navMenu ul {
      display: block;
      height: auto;
      border-bottom: 6px solid #316ab2; }
      .l-navMenu ul li {
        border-bottom: 1px solid #316ab2; }
        .l-navMenu ul li:last-child {
          border-bottom: none; }
        .l-navMenu ul li a {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          height: 59px;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          padding-left: 38px; }
  .c-adp__button {
    display: block; }
  .c-adpCart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 290px;
    margin: 0 auto; }
  body.home .l-slider {
    display: none; }
  .ht, .hm {
    background: transparent; }
  header {
    background: url("../images/adp-head-bg.jpg"); }
  .c-headAuth__link {
    background: #457fc7;
    max-width: 290px;
    margin: 0 auto; }
  .c-hm__mail {
    display: none; }
  .l-headCart {
    display: none; }
  .l-hm__top {
    display: block; }
  .c-logo {
    max-width: 320px;
    margin: 0 auto 30px; }
  .c-hm__phones {
    border-left: none;
    padding-left: 0;
    margin-right: 0;
    width: 100%; }
  .l-hm__bottom {
    display: block;
    height: auto;
    padding: 10px 36px;
    font-size: 12px; }
  .c-hm__officeAddress {
    margin-right: 0;
    margin-bottom: 20px;
    padding-left: 15px; }
  body.home .hm {
    height: auto;
    padding-bottom: 35px; }
  .c-headSearch form {
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    height: auto; }
  .c-headSearch input[type=text] {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3px; }
  .c-headSearch .c-searchSelect + .select2 {
    width: calc(100% - 67px) !important; }
  .hb {
    height: auto;
    padding: 12px 0; }
    .hb > .container {
      width: 100%; }
  .c-footNav {
    display: none; }
  .l-catItem {
    width: 100%; }
  .c-catItem {
    max-width: 220px;
    margin: 0 auto; }
  .c-homeSlider {
    width: 193px; }
    .c-homeSlider .slick-arrow {
      width: 27px;
      height: 41px;
      background-size: cover;
      background: url("../images/slider-arrow--adp.png") no-repeat 0 0; }
      .c-homeSlider .slick-arrow:hover {
        background-position-x: 0; }
    .c-homeSlider .slick-next {
      right: -49px;
      background-position-y: -41px; }
      .c-homeSlider .slick-next:hover {
        background-position-y: -41px; }
    .c-homeSlider .slick-prev {
      left: -49px; }
  .c-homeExc {
    padding: 21px 35px 43px; }
    .c-homeExc .c-title {
      white-space: normal;
      font-size: 18px;
      left: 21px;
      max-width: 238px;
      text-align: center; }
  .c-homeExc__item {
    width: 100%;
    margin-top: 40px; }
  .l-homeSeotext {
    font-size: 14px; }
    .l-homeSeotext .c-title {
      font-size: 18px; }
  .c-button {
    width: 220px;
    height: 51px;
    font-size: 16px; }
  footer {
    border-top: 20px solid #316ab2;
    position: relative;
    padding-bottom: 50px; }
    footer > .container {
      padding: 0; }
  .l-copy {
    margin-bottom: 24px; }
  .c-copy {
    text-align: center; }
  .l-footAddress {
    margin-bottom: 24px;
    text-align: center; }
    .l-footAddress > div {
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      .l-footAddress > div > span {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 4px; }
  .l-footContacts > div:last-child {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .fm {
    padding: 28px 0 25px; }
  .l-footSearch {
    margin-bottom: 30px; }
  .l-footCounters {
    text-align: center;
    margin-bottom: 30px; }
  .l-footDevelop {
    text-align: center; }
    .l-footDevelop a br {
      display: none; }
  #up {
    position: absolute;
    bottom: 0;
    margin-left: 0;
    height: 50px;
    width: 100%;
    left: 0; }
  .c-breadcrumbs {
    padding: 20px 0; }
  .captcha {
    position: relative;
    height: 61.58px; }
    .captcha #captcha {
      position: absolute;
      top: -7px;
      left: -32px;
      -webkit-transform: scale(0.78948);
      -ms-transform: scale(0.78948);
      transform: scale(0.78948); }
  .c-cartBlc .c-button {
    width: 100%;
    max-width: 240px;
    margin: 0 auto; }
  .l-catPage__item {
    width: 100%; }
  .c-catPage__item {
    display: block;
    padding: 28px 25px 24px 25px; }
  .c-catPage__img {
    margin-bottom: 20px;
    margin-right: 0;
    text-align: center; }
  .l-catPageCategory {
    width: 50%; }
  .c-filter {
    margin: -20px auto 30px; }
  .for-print {
    display: none; }
  .c-page-title--link {
    font-size: 20px; }
  .c-product__top {
    display: block; }
  .l-product__gallery {
    width: 100%;
    max-width: 290px;
    margin: 0 auto 25px;
    height: auto; }
  .l-productInfo {
    width: 100%; }
    .l-productInfo .c-pin {
      padding-left: 0; }
  .c-productInfo {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px); }
  .c-product__galleryNav {
    width: 200px; }
  .c-product__gallery .c-slide {
    height: 320px; }
  .w-product__gallery {
    height: 325px; }
  .navN ul {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .c-productTable .cart-foot {
    display: block; }
    .c-productTable .cart-foot .c-lastUpd {
      text-align: center;
      margin-top: 30px; }
  .l-tabsAdp {
    display: block; }
  .l-tabs {
    display: none; }
  .l-lkNav {
    margin-top: 30px; }
  .c-lkBlc--settings .field {
    display: block;
    width: 100%; }
  .c-lkBlc--settings .lbl {
    margin-bottom: 7px; }
  .c-news__item {
    display: block; }
  .c-news__img {
    margin-right: 0;
    margin-bottom: 20px;
    max-width: 290px; }
    .c-news__img img {
      width: 100%;
      height: auto; }
  .c-newsPage__img {
    float: none; }
  .arcticmodal-container_i2 {
    padding: 0; }
  .field--modal {
    display: block; }
    .field--modal .pcheck {
      margin-bottom: 10px; } }

@media all and (min-width: 320px) and (max-width: 479px) {
  .box-modal {
    width: 100%;
    max-width: 440px; } }

@media all and (min-width: 320px) and (max-width: 991px) {
  .c-sidebarNav {
    display: none; }
  .c-sidebarNavAdp {
    display: block; }
  .c-cartTable__foot {
    height: auto;
    padding: 10px;
    display: block; }
  .c-cartTable__foot-links {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 15px; }
    .c-cartTable__foot-links a {
      margin-bottom: 7px; }
      .c-cartTable__foot-links a:last-child {
        margin-bottom: 0; }
    .c-cartTable__foot-links a:first-child {
      padding-left: 0; }
      .c-cartTable__foot-links a:first-child:before {
        display: none; }
    .c-cartTable__foot-links .sep {
      display: none; }
  .c-cartTable__foot-sum {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-right: 0;
    font-size: 14px;
    text-align: right; }
  .c-title {
    font-size: 18px; }
  .c-title--blc {
    left: 21px; }
  .c-cartBlc--shipping .field {
    display: block;
    width: 100%; }
    .c-cartBlc--shipping .field .lbl {
      width: 100%;
      padding: 0;
      margin-bottom: 13px; }
  .pcheck__row {
    display: block; }
  .pcheck__item {
    margin-right: 0;
    margin-bottom: 17px; }
    .pcheck__item:last-child {
      margin-bottom: 0; }
  .l-shippingTable {
    width: 100%; }
  .c-cartBlc--contens .field {
    display: block;
    width: 100%; }
    .c-cartBlc--contens .field .lbl {
      width: 100%;
      padding: 0;
      margin-bottom: 13px; }
  .c-cartBlc__bottom {
    padding: 30px 20px 0; } }

@media all and (min-width: 480px) and (max-width: 767px) {
  .l-headAuth {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .c-headAuth__link {
    width: 50%;
    max-width: none;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    height: 55px; }
  .c-adpCart {
    height: 55px;
    padding: 0;
    width: 50%;
    max-width: none;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 30px; }
    .c-adpCart .c-headCart__info {
      font-size: 12px; }
      .c-adpCart .c-headCart__info span {
        line-height: 1;
        white-space: nowrap;
        display: block; }
        .c-adpCart .c-headCart__info span:first-child {
          margin-right: 0;
          margin-bottom: 4px; }
  .c-headAuth__link--false a:first-child:before,
  .c-headAuth__link--true a:first-child:before {
    height: 55px;
    width: 60px; }
  .c-hm__phones {
    padding-right: 45px;
    width: calc(100% - 150px);
    font-size: 14px;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .c-hm__phones > div:first-child {
      margin-right: 22px; }
  .c-hm__mail {
    display: block;
    font-size: 14px; }
  .l-hm__top {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .l-hm__bottom {
    font-size: 14px;
    text-align: center; }
  .c-hm__officeAddress {
    padding-left: 0;
    text-align: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .c-hm__skladAddress {
    text-align: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .l-catItem {
    width: 50%; }
    .l-catItem:nth-child(odd) {
      text-align: right; }
    .l-catItem:nth-child(even) {
      text-align: left; }
  .c-catItem {
    margin: 0;
    display: inline-block; }
  .c-homeSlider {
    width: 372px; }
    .c-homeSlider .slick-next {
      right: -39px; }
    .c-homeSlider .slick-prev {
      left: -39px; }
  .c-homeExc .c-title {
    max-width: 362px; }
  .c-homeExc__item {
    width: 50%; }
  .l-footCounters {
    width: 50%;
    text-align: left;
    float: left;
    margin-bottom: 0; }
  .l-footDevelop {
    width: 50%;
    text-align: right;
    float: left; }
    .l-footDevelop a br {
      display: initial; } }

@media all and (min-width: 768px) and (max-width: 991px) {
  .c-homeSlider {
    width: 599px; }
  .l-headCart {
    display: none; }
  .ht > .container {
    position: relative; }
  .l-headAuth {
    position: absolute;
    top: 50px;
    z-index: 10;
    width: 100%; }
  .l-headAuth {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .c-headAuth__link {
    width: 50%;
    max-width: none;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    height: 55px; }
  .c-adpCart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 55px;
    padding: 0;
    width: 50%;
    max-width: none;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 30px;
    background: #316ab2; }
    .c-adpCart .c-headCart__info {
      font-size: 14px; }
      .c-adpCart .c-headCart__info span {
        line-height: 1;
        white-space: nowrap; }
        .c-adpCart .c-headCart__info span:first-child {
          margin-right: 12px; }
  .c-headAuth__link--false a:first-child:before,
  .c-headAuth__link--true a:first-child:before {
    height: 55px;
    width: 60px; }
  .l-navMenu ul a {
    font-size: 14px; }
  .c-dropMenu__link a {
    font-size: 14px; }
  .l-hm__left {
    position: relative; }
  .l-hm__left {
    padding-top: 89px; }
  .c-logo {
    width: 320px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 66px; }
  .c-hm__phones {
    padding-left: 65px;
    font-size: 14px;
    margin-right: 0; }
  .c-hm__mail {
    display: none; }
  .l-hm__bottom {
    font-size: 14px;
    padding: 0 20px; }
  .c-hm__officeAddress {
    margin-right: 20px; }
  .c-hm__skladAddress .c-hm__addressIcon,
  .c-hm__officeAddress .c-hm__addressIcon {
    margin-right: 14px; }
  .c-headSlider .c-slide__title {
    font-size: 30px;
    margin: 0 0 11px; }
  .c-headSlider .c-slide {
    padding-top: 276px; }
  .c-headSlider .c-slide__text {
    font-size: 14px;
    line-height: 20px; }
  .c-slide__button .c-button {
    height: 51px;
    font-size: 16px; }
  .c-headSlider .dots-container {
    top: -42px; }
  .c-headSearch input[type=text] {
    width: 446px; }
  .l-catItem {
    width: 25%; }
  .c-catItem__name {
    font-size: 12px; }
  .c-catItem__links a {
    font-size: 12px; }
  .c-homeExc__item {
    width: 33.333333%; }
  .c-homeExc__text {
    font-size: 14px; }
  .c-button {
    width: 220px;
    height: 51px;
    font-size: 16px; }
  .c-footNav a {
    font-size: 14px; }
  .c-copy, .l-footAddress, .l-footContacts {
    font-size: 12px; }
  footer {
    position: relative;
    padding-bottom: 50px; }
    footer > .container {
      padding: 0; }
  #up {
    position: absolute;
    bottom: 0;
    margin-left: 0;
    height: 50px;
    width: 100%;
    left: 0; }
  .c-filter {
    margin-top: -20px;
    margin-bottom: 30px; }
  .l-lkNav {
    margin-top: 30px; } }

@media all and (min-width: 992px) and (max-width: 1199px) {
  .c-homeSlider {
    width: 803px; }
  .l-headCart {
    display: none; }
  .ht > .container {
    position: relative; }
  .l-headAuth {
    position: absolute;
    top: 50px;
    z-index: 10;
    width: 100%; }
  .l-headAuth {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .c-headAuth__link {
    width: 50%;
    max-width: none;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0;
    height: 55px; }
  .c-adpCart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 55px;
    padding: 0;
    width: 50%;
    max-width: none;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 30px;
    background: #316ab2; }
    .c-adpCart .c-headCart__info {
      font-size: 14px; }
      .c-adpCart .c-headCart__info span {
        line-height: 1;
        white-space: nowrap; }
        .c-adpCart .c-headCart__info span:first-child {
          margin-right: 12px; }
  .c-headAuth__link--false a:first-child:before,
  .c-headAuth__link--true a:first-child:before {
    height: 55px;
    width: 60px; }
  .l-dropMenu {
    padding-right: 0; }
  .l-navMenu nav {
    padding-left: 15px; }
  .l-navMenu ul a {
    font-size: 16px; }
  .c-dropMenu__link a {
    font-size: 16px; }
  .l-hm__left {
    position: relative; }
  .l-hm__left {
    padding-top: 89px; }
  .c-logo {
    width: 320px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 66px; }
  .c-hm__phones {
    padding-left: 65px;
    font-size: 14px;
    margin-right: 52px; }
  .l-hm__bottom {
    font-size: 14px;
    padding: 0 20px; }
  .c-hm__officeAddress {
    margin-right: 20px; }
  .c-hm__skladAddress .c-hm__addressIcon,
  .c-hm__officeAddress .c-hm__addressIcon {
    margin-right: 14px; }
  .c-headSlider .c-slide__title {
    font-size: 30px;
    margin: 0 0 11px; }
  .c-headSlider .c-slide {
    padding-top: 276px; }
  .c-headSlider .c-slide__text {
    font-size: 14px;
    line-height: 20px; }
  .c-slide__button .c-button {
    height: 51px;
    font-size: 16px; }
  .c-headSlider .dots-container {
    top: -42px; }
  .c-headSearch input[type=text] {
    width: 647px; }
  .l-catItem {
    width: 25%; }
  .c-catItem__name {
    font-size: 12px; }
  .c-catItem__links a {
    font-size: 12px; }
  .c-homeExc__item {
    width: 33.333333%;
    padding: 0 30px; }
  .c-homeExc__text {
    font-size: 18px; }
  .c-footNav a {
    font-size: 16px; }
  .c-button {
    width: 262px;
    height: 60px;
    font-size: 18px; }
  .l-footAddress > div {
    display: block; }
    .l-footAddress > div > span {
      display: block;
      margin-bottom: 3px; } }

@media print {
  .container {
    width: 21cm; }
  .c-hm__phones {
    width: 11cm; }
  a[href]:after {
    content: ''; }
  .c-hm__officeAddress {
    margin-right: 15px; }
  .ht, .l-headCart, .hb, .c-breadcrumbs, .for-print, .l-sidebar, footer, .c-product__galleryNav {
    display: none; }
  .l-hm__left, .l-content {
    width: 100%; }
  .c-tabs li {
    page-break-after: always; }
    .c-tabs li:last-child {
      page-break-after: auto; }
  .navN {
    visibility: hidden; }
  .l-product__gallery {
    width: 345px; }
  .l-productInfo {
    width: calc(100% - 345px); }
  .c-productTable tbody td.tc2 {
    width: 180px; }
  .c-productTable .tc10, .c-productTable .tc11 {
    display: none; } }
