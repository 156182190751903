.c-breadcrumbs {
	padding: 26px 0;
	font-family: $sans-font;
	color: #3e4144;
	font-size: 16px;
	a {
		text-decoration: none;
		color: #3e4144;
		&:hover, &:active, &:focus {
			color: #316ab2;
			text-decoration: none;
		}
	}
	.sep {
		width: 4px;
		height: 7px;
		display: inline-block;
		background: url("../images/bc-sep.png") no-repeat 0 0;
		margin: 0 14px;
	}
	span:last-child {
		color: #81878d;
	}
}