@import "var.scss";

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body, html {
	height: 100%;
}

body {
	font-family: $main-font;
	font-size: 16px;
	color: $main-color;
	line-height: 1.2;
}

a {
	color: $link-color;
	text-decoration: underline;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $link-color--hover;
	}
}

ol, ul {
	list-style-position: inside;
}

img {
	max-width: 100%;
	height: auto;
}

p {
	line-height: 24px;
	&:last-child {
		margin-bottom: 0;
	}
}