.c-lkNav {
	ul {
		list-style-type: none;
		margin: 0;
	}
	li {
		&:first-child {
			a {
				border-top: 1px solid #d4d9e0;
			}
		}
		&.on {
			a {
				background: #457fc7;
				border-color: #457fc7;
				color: #ffcd51;
			}
		}
	}
	a {
		display: block;
		text-decoration: none;
		background: #e8ecf1;
		border: 1px solid #d4d9e0;
		text-align: center;
		color: #32629e;
		font-size: 16px;
		font-family: $sans-font;
		padding: 11px 10px 10px;
		border-top: none;
		&:hover, &:active, &:focus {
			text-decoration: none;
			background: #dde2e9;
		}
	}
}

.c-lkBlc{
	&--settings {
		.field {
			display: flex;
			margin-bottom: 10px;
			align-items: center;
			width: 423px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.lbl {
			width: 153px;
			flex-shrink: 0;
		}
		.forbut {
			margin-top: 30px;
		}
		.help-block {
			max-width: 174px;
			line-height: 1;
		}
	}
}

.c-lkBlc__title {
	margin: 0 0 27px;
	font-size: 18px;
	font-family: $sans-font;
	font-weight: bold;
	color: #3e4144;
}

.c-historyTable {
	table {
		table-layout: fixed;
		border-collapse: separate;
		border-spacing: 0 2px;
		width: 100%;
		text-align: center;
		font-family: $sans-font;
		font-size: 14px;
		thead {
			th {
				height: 57px;
				background: #457fc7;
				font-weight: normal;
				color: #fff;
				text-align: center;
			}
		}
		tbody {
			tr {
				&:hover {
					& > td:not(.tc5) {
						background: #fffaed;
					}
				}
			}
			td {
				border: 1px solid #d4d9e0;
				border-left: none;
				height: 51px;
				color: #3e4144;
				&:first-child {
					border-left: 1px solid #d4d9e0;
					a {
						font-weight: bold;
						color: #316ab2;
						&:hover, &:active, &:focus {
							text-decoration: none;
							color: #6199df;
						}
					}
				}
				&.tc5 {
					background: #457fc7;
					border-color: #457fc7;
					padding: 10px 14px;
				}
			}
		}
		.tc1 {
			width: 95px;
		}
		.tc2 {
			width: 144px;
		}
		.tc3 {
			width: 118px;
		}
		.tc4 {
			width: 132px;
		}
		.tc5 {
			width: 160px;
		}
	}
}

.c-historyTable__button {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #ffcd51;
	text-decoration: none;
	border: 2px solid #ffcd51;
	border-radius: 17px;
	height: 34px;
	text-transform: uppercase;
	font-weight: bold;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #ffdc51;
		border-color: #ffdc51;
	}
}

.c-history__info {
	margin-top: 27px;
	margin-bottom: 25px;
	color: #3e4144;
	font-size: 16px;
	div {
		margin-bottom: 10px;
	}
	.blueText {
		margin-top: 23px;
		margin-bottom: 0;
		font-weight: bold;
		color: #316ab2;
		font-size: 18px;
	}
}