.l-homeCatalog {
	background: url('../images/cat-bg.png') 0 0;
	padding: 30px 0;
}

.c-catList {
	margin: 0 -5px;
	display: flex;
	flex-flow: row wrap;
}

.l-catItem {
	width: 20%;
	padding: 0 5px;
	margin-bottom: 10px;
}

.c-catItem {
	border: 1px solid #d4d9e0;
	background: #fff;
	border-radius: 9px;
	overflow: hidden;
	&:hover {
		.w-catItem--main {
			display: none;
		}
		.w-catItem--hover {
			display: block;
		}
	}
}

.c-catItem__img {
	height: 236px;
	border-bottom: 1px solid #d4d9e0;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: #fff;
}

.c-catItem__name {
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: #e8ecf1;
	text-transform: uppercase;
	font-size: 14px;
	font-family: $sans-font;
	color: #32629e;
	font-weight: bold;
	line-height: 1.2;
}

.w-catItem--main {
	display: block;
}

.w-catItem--hover {
	display: none;
	.c-catItem__name {
		height: 69px;
	}
}

.c-catItem__links {
	display: flex;
	flex-direction: column;
	height: 166px;
	align-items: center;
	text-align: center;
	padding: 0 12px;
	background: #e8ecf1;
	overflow: hidden;
	a {
		font-size: 14px;
		font-family: $sans-font;
		text-decoration: underline;
		color: #3e4144;
		margin-bottom: 13px;
		&:last-child {
			margin-bottom: 0;
		}
		&:hover, &:active, &:focus {
			color: #457fc7;
			text-decoration: none;
		}
	}
}

.c-catItem__all {
	height: 81px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #e8ecf1;
	a {
		color: #32629e;
		font-family: $sans-font;
		font-weight: bold;
		font-size: 14px;
		text-transform: uppercase;
		text-decoration: underline;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #457fc7;
		}
	}
}

.l-catPage {
	display: flex;
	flex-flow: row wrap;
	margin: 0 -15px;
	font-family: $sans-font;
	margin-bottom: 17px;
}

.l-catPage__item {
	width: 50%;
	padding: 0 15px;
	margin-bottom: 25px;
}

.c-catPage__name {
	display: inline-block;
	margin-bottom: 20px;
	text-transform: uppercase;
	color: #32629e;
	font-size: 14px;
	font-weight: bold;
	text-decoration: none;
	span {
		color: #3e4144;
	}
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #457fc7;
		span {
			color: #457fc7;
		}
	}
}

.i-catPage__item {
	position: relative;
	height: 214px;
}

.w-catPage__item {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 100;
	background: #457fc7;
	padding-bottom: 5px;
	border-radius: 10px;
	border-bottom-left-radius: 9px;
	border-bottom-right-radius: 9px;
	overflow: hidden;
}

.c-catPage__item {
	background: #ffffff;
	border: 1px solid #d4d9e0;
	border-radius: 9px;
	overflow: hidden;
	display: flex;
	align-items: flex-start;
	padding: 28px 25px 24px 10px;
	height: 209px;
	min-height: 209px;
	&:hover {
		height: auto;
	}
}

.c-catPage__img {
	flex-shrink: 0;
	margin-right: 10px;
}

.c-catPage__linkList {
	position: relative;
	top: -0.25em;
	ul {
		list-style-type: none;
		margin: 0;
		li {
			margin-bottom: 7px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a {
			padding-left: 17px;
			position: relative;
			font-size: 14px;
			color: #3e4144;
			line-height: 1.2;
			display: inline-block;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 6px;
				width: 6px;
				height: 6px;
				background: #316ab2;
				border-radius: 50%;
			}
			&:hover, &:active, &:focus {
				text-decoration: none;
				color: #457fc7;
				&:before {
					background: #457fc7;
				}
			}
		}
	}
}

.l-catPageCategory {
	padding: 0 15px;
	width: 25%;
	text-align: center;
	margin-bottom: 30px;
}

.w-catPageCategory__img {
	border-radius: 10px;
	border-bottom-left-radius: 9px;
	border-bottom-right-radius: 9px;
	overflow: hidden;
	background: #457fc7;
	padding-bottom: 5px;
	margin-bottom: 16px;
}

.c-catPageCategory__img {
	border: 1px solid #d4d9e0;
	border-radius: 9px;
	overflow: hidden;
	padding: 8px 0 7px;
	background: #ffffff;
	text-align: center;
}

.c-catPageCategory__name {
	font-size: 14px;
	font-family: $sans-font;
	font-weight: bold;
	color: #32629e;
	text-transform: uppercase;
	display: inline-block;
	margin-bottom: 7px;
	text-decoration: none;
}

.c-catPageCategory__art {
	font-size: 14px;
	font-family: $sans-font;
	color: #63676b;
}

.c-product__top {
	display: flex;
}

.l-product__gallery {
	width: 40.709%;
	flex-shrink: 0;
	height: 472px;
	overflow: hidden;
	&--min {
		height: auto;
	}
}

.l-productInfo {
	width: 59.291%;
	flex-shrink: 0;
	.c-pin {
		padding-left: 30px;
	}
}

.w-product__gallery {
	padding-bottom: 5px;
	background: #457fc7;
	border-radius: 10px;
	border-bottom-left-radius: 9px;
	border-bottom-right-radius: 9px;
	overflow: hidden;
	margin-bottom: 18px;
	height: auto;
	&--nomg {
		margin-bottom: 0;
	}
}

.c-product__gallery {
	.c-slide {
		border: 1px solid #d4d9e0;
		border-radius: 9px;
		overflow: hidden;
		background: #ffffff;
		height: 379px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
}

.c-product__galleryNav {
	width: 282px;
	margin: 0 auto;
	.slick-arrow {
		width: 20px;
		height: 30px;
		position: absolute;
		top: calc(50% - 15px);
		background: url('../images/slider-nav--prod.png') no-repeat 0 0;
		border: none;
		outline: none;
		&:hover {
			background-position-x: -20px;
		}
	}
	.slick-prev {
		left: -32px;
	}
	.slick-next {
		right: -32px;
		background-position-y: -30px;
	}
	.c-slide {
		cursor: pointer;
		margin: 0 2px;
	}
}

.c-productInfo {
	padding: 24px 30px 25px 40px;
	margin-left: -10px;
	margin-bottom: 25px;
	background: url("../images/prod-info--bg.png");
	font-family: $sans-font;
	color: #fff;
	font-size: 16px;
	div {
		margin-bottom: 11px;
		&:last-child {
			margin-bottom: 0;
		}
		b {
			text-transform: uppercase;
			margin-right: 9px; 
		}
		a {
			color: #fecf5b;
			text-decoration: underline;
			&:hover, &:active, &:focus {
				text-decoration: none;
				color: #fecf5b;
			}
		}
	}
}

.c-product__top {
	padding-bottom: 40px;
	border-bottom: 5px solid #e8ecf1;
	margin-bottom:  30px;
	&--noborder {
		border-bottom: none;
		padding-bottom: 0;
	}
	&--min {
		margin-bottom: 24px;
	}
}

.c-product__bottom {
	& > .c-pin {
		margin-bottom: 30px;
	}
}

.c-productTable {
	font-family: $sans-font;
	&--mg {
		margin-bottom: 30px;
	}
	table {
		border-collapse: separate;
		border-spacing: 0 2px;
		table-layout: fixed;
	}
	thead {
		th {
			text-align: center;
			height: 57px;
			background: #457fc7;
			font-size: 13px;
			font-weight: normal;
			color: #ffffff;
		}
	}
	tbody {
		tr {
			&:hover {
				& > td:not(.tc10) {
					background: #fffaed;
				}
			}
			&.new {
				td:first-child {
					a {
						position: relative;
						height: 51px;
						display: flex;
						align-items: center;
						text-align: center;
						width: 39px;
						&:before {
							content: '';
							width: 34px;
							height: 34px;
							display: block;
							background: url("../images/new-min.png") no-repeat 0 0;
							position: absolute;
							left: 0;
							top: 0;
						}
					}
				}
			}
			&.sale {
				td:first-child {
					a {
						position: relative;
						height: 51px;
						display: flex;
						align-items: center;
						text-align: center;
						&:before {
							content: '';
							width: 34px;
							height: 34px;
							display: block;
							background: url("../images/sale-min.png") no-repeat 0 0;
							position: absolute;
							left: 0;
							top: 0;
						}
					}
				}
			}
		}
		td {
			border: 1px solid #d4d9e0;
			border-left: none;
			text-align: center;
			font-size: 13px;
			&:first-child {
				border-left: 1px solid #d4d9e0;
			}
			&.tc1 {
				a {
					display: block;
				}
			}
			&.tc2 {
				width: 83px;
			}
			&.tc9 {
				width: 90px;
			}
			&.tc10 {
				background: #f1f4f7;
				width: 58px;
				input {
					background: transparent;
					width: 100%;
					height: 49px;
					border: none;
					outline: none;
					text-align: center;
					color: #8a8b93;
					padding-left: 10px;
					font-family: $sans-font;
					font-size: 14px;
					&:focus {
						color: #3e4144;
					}
				}
			}
			&.tc11 {
				width: 40px;
				button {
					border: none;
					outline: none;
					width: 40px;
					display: block;
					height: 51px;
					background-color: #457fc7;
					background: url("../images/tocart.png") no-repeat 0 0;
					&:hover {
						background-color: #ffcd51;
						background-position: 0 -51px;
					}
				}
			}
		}
	}
	.tc1 {
		width: 41px; 
		img {
			width: 100%;
		}
	}
	.tc2 {
		width: 83px;
		text-align: left;
		padding: 0 22px 0 14px;
	}
	.tc3 {
		width: 46px;
	}
	.tc4 {
		width: 103px;
	}
	.tc5 {
		width: 129px;
	}
	.tc6 {
		width: 124px;
	}
	.tc7 {
		width: 88px;
	}
	.tc8 {
		width: 43px;
	}
	.tc9 {
		width: 86px;
	}
	.tc10 {
		width: 58px;
	}
	.cart-foot {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.c-lastUpd {
	font-family: $sans-font;
	color: #81878d;
	font-size: 16px;
	line-height: 1;
	white-space: nowrap;
}

.i-product__gallery {
	border: 1px solid #d4d9e0;
	border-radius: 9px;
	overflow: hidden;
	height: auto;
	position: relative;
	z-index: 10;
	img {
		
	}
}