.pcheck__row {
	display: flex;
	align-items: center;
}

.pcheck__item {
	margin-right: 28px;
}

.c-customRadio {
	position: absolute;
	visibility: hidden;
	& + label {
		font-size: 14px;
		color: #57595d;
		margin-bottom: 0;
		position: relative;
		padding-left: 31px;
		cursor: pointer;
		font-weight: normal;
		&:before {
			position: absolute;
			left: 0;
			top: calc(50% - 8px);
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			background: transparent;
			border-radius: 50%;
			border: 1px solid rgba(180,184,191,.95);
		}
	}
	&:checked {
		& + label {
			&:after {
				position: absolute;
				top: calc(50% - 4px);
				left: 4px;
				content: '';
				display: block;
				width: 8px;
				height: 8px;
				background: #316ab2;
				border-radius: 50%;
			}
		}
	}
}