@media print {
	.container {
		width: 21cm;
	}
	.c-hm__phones {
		width: 11cm;
	}
	a[href]:after {
		content: '';
	}
	.c-hm__officeAddress {
		margin-right: 15px;
	}
	.ht, .l-headCart, .hb, .c-breadcrumbs, .for-print, .l-sidebar, footer, .c-product__galleryNav {
		display: none;
	}
	.l-hm__left, .l-content {
		width: 100%;
	}
	.c-tabs li {
		page-break-after: always;
		&:last-child {
			page-break-after: auto;
		}
	}
	.navN {
		visibility: hidden;
	}
	.l-product__gallery {
		width: 345px;
	}
	.l-productInfo {
		width: calc(100% - 345px);
	}
	.c-productTable {
		tbody td.tc2 {
			width: 180px;
		}
		.tc10, .tc11 {
			display: none;
		}
	}
}