.l-tabs {
	margin-bottom: 24px;
}

.c-tabs {
	display: flex;
	align-items: flex-end;
	list-style-type: none;
	font-family: $sans-font;
	margin: 0;
	li {
		margin-right: 5px;
		padding: 9.5px 23px;
		background: #457fc7;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: #f2f8ff;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		cursor: pointer;
		border: 1px solid #457fc7;
		border-bottom: none;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		min-height: 52px;
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			background: #6199df;
			border-color: #6199df;
		}
		&.on {
			padding: 14px 23px;
			min-height: 61px;
			background: #e8ecf1;
			color: #457fc7;
			border-color: #d4d9e0;
			position: relative;
			&:before {
				content: '';
				display: block;
				height: 1px;
				background: #e8ecf1;
				width: 100%;
				position: absolute;
				bottom: -1px;
				left: 0;
			}
		}
	}
}

.c-tabs__content {
	display: none;
	border: 1px solid #d4d9e0;
	background: #e8ecf1;
	padding: 34px 38px 40px;
	&.on {
		display: block;
	}
}

.l-tabsAdp {
	display: none;
	ul {
		list-style-type: none;
		padding: 0;
	}
	li {
		margin-bottom: 2px;
		&:first-child {
			.c-tabsAdp__title {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				overflow: hidden;
			}
		}
		&:last-child {
			margin-bottom: 0;
			.c-tabsAdp__title {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				overflow: hidden;
			}
			&.on {
				.c-tabsAdp__title {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					overflow: hidden;
				}
				.c-tabsAdp__content {
					border-bottom-left-radius: 10px;
					border-bottom-right-radius: 10px;
					overflow: hidden;
				}
			}
		}
		&.on {
			.c-tabsAdp__title {
				border-color: #d4d9e0;
				background: #e8ecf1;
				color: #457fc7;
			}
			.c-tabsAdp__content {
				display: block;
			}
		}
	}
}

.c-tabsAdp__content {
	display: none;
	background: #e8ecf1;
	border: 1px solid #d4d9e0;
	border-top: none;
	padding: 17px 30px 43px;
}

.c-tabsAdp__title {
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: #457fc7;
	color: #f2f8ff;
	font-size: 14px;
	text-transform: uppercase;
	font-family: $sans-font;
	font-weight: bold;
	border: 1px solid transparent;
	border-bottom: none;
}