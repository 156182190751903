body.home {
	.hm {
		height: 469px;
		padding-bottom: 0;
	}
}

header {
	padding-top: 50px;
}

.ht {
	background: #457fc7;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1000;
	&.fix {
		
	}
}

.c-dropMenu__link {
	background: #316ab2;
	position: relative;
	a {
		display: flex;
		height: 50px;
		color: #ffcd51;
		text-decoration: none;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-family: $sans-font;
		font-weight: bold;
		line-height: 1;
		white-space: nowrap;
	}
	&:hover {
		background: #1c5eb1;
		& > a {
			color: #ffdc51;
		}
		.w-dropMenu {
			display: flex;
		}
	}
}

.c-dropMenu__list {
	width: 263px;
}

.w-dropMenu {
	z-index: 3;
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	min-width: 263px;
	ul {
		background: #1c5eb1;
		padding-left: 18px;
		list-style-type: none;
		margin: 0;
		li {
			border: 1px solid #d4d9e0;
			border-top: none;
			border-left: none;
			&:first-child {
				border-top: 1px solid #d4d9e0;
			}
			&:last-child {
				border-bottom: none;
			}
			&:hover, &:active, &:focus, &.active {
				a {
					background: #dde2e9;
					color: #32629e;
				}
			}
		}
		a {
			display: block;
			white-space: normal;
			padding: 12px 15px 12px;
			background: #e8ecf1;
			font-size: 16px;
			color: #32629e;
			text-align: left;
			height: auto;
		}
	}
}

.l-navMenu {
	ul {
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		margin: 0;
		li.active {
			a {
				font-weight: bold;
				color: #ffcd51;
			}
		}
		a {
			text-decoration: none;
			color: #ffffff;
			font-family: $sans-font;
			line-height: 1;
			white-space: nowrap;
			&:hover, &:active, &:focus {
				color: #f4c03e;
				text-decoration: none;
			}
		}
	}
}

.c-headAuth__link {
	background: #316ab2;
	display: flex;
	align-items: center;
	height: 50px;
	position: relative;
	a {
		font-family: $sans-font;
		font-weight: bold;
		font-size: 14px;
		color: #ffcd51;
		text-transform: uppercase;
		text-decoration: none;
		&:hover, &:active, &:focus {
			color: #ffdc51;
			text-decoration: none;
		}
	}
	&--false {
		a:first-child {
			padding-left: 80px;
			&:before {
				position: absolute;
				content: '';
				display: block;
				width: 54px;
				height: 50px;
				top: 0;
				left: 0;
				background-color: #ffcd51;
				background-image: url("../images/auth-icon.png");
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
			&:hover {
				&:before {
					background-color: #ffdc51;
				}
			}
		}
	}
	&--true {
		justify-content: center;
		text-align: center;
		a {
			padding-left: 54px;
			&:before {
				position: absolute;
				content: '';
				display: block;
				width: 54px;
				height: 50px;
				top: 0;
				left: 0;
				background-color: #ffcd51;
				background-image: url("../images/exit-icon.png");
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
			&:hover {
				&:before {
					background-color: #ffdc51;
				}
			}
		}
	}
}

.c-headAuth__sep {
	margin: 0 12px 0 15px;
	font-size: 14px;
	font-family: $sans-font;
	font-weight: bold;
	color: #ffcd51;
}

.hm {
	position: relative;
	padding-bottom: 35px;
	background: url("../images/hm-bg.jpg") no-repeat 50% 50%;
	background-size: cover;
	& > .container {
		position: relative;
		z-index: 2;
	}
}

.l-hm__left {
	padding-top: 25px;
}

.l-hm__top {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}

.c-logo {
	display: block;
	margin-right: 30px;
}

.c-hm__phones {
	padding-left: 30px;
	border-left: 3px solid #fbc02d;
	color: #fefefe;
	font-family: $sans-font;
	font-size: 16px;
	display: flex;
	align-items: center;
	margin-right: 30px;
	justify-content: space-between;
	width: 337px;
	& > div {
		display: flex;
		flex-direction: column;
	}
}

.c-hm__mail {
	padding-left: 37px;
	text-decoration: none;
	color: #fefefe;
	font-family: $sans-font;
	font-size: 16px;
	position: relative;
	line-height: 1;
	white-space: nowrap;
	&:hover, &:active, &:focus {
		color: #ffcd51;
		text-decoration: none;
	}
	&:before {
		position: absolute;
		content: '';
		display: block;
		width: 23px;
		height: 16px;
		background: url("../images/mail-icon.png") no-repeat 0 0;
		left: 0;
		top: calc(50% - 7px);
	}
}

.w-headCart {
	background: #316ab2;
	padding: 29px 20px 24px;
}

.c-headCart {
	border: 2px solid #f9ca54;
	padding: 30px 20px 25px 30px;
	position: relative;
	display: flex;
	align-items: center;
	min-height: 99px;
}

.c-headCart__title {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
	font-family: $sans-font;
	color: #f3c34b;
	font-weight: bold;
	position: absolute;
	display: block;
	padding: 0 10px;
	top: -9px;
	left: 28px;
	background: #316ab2;
	line-height: 1;
	white-space: nowrap;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #ffdc51;
	}
}

.c-headCart__icon {
	display: block;
	margin-right: 15px;
	width: 38px;
	height: 35px;
	background: url("../images/cart-icon.png") no-repeat 0 0;
	flex-shrink: 0;
	&:hover {
		background-position: 0 -35px;
	}
}

.c-headCart__info {
	color: #fefefe;
	font-size: 14px;
	font-family: $sans-font;
	line-height: 20px;
	min-height: 40px;
}

.l-hm__bottom {
	display: flex;
	height: 45px;
	padding: 0 34px;
	border-radius: 9px;
	border: 2px solid rgba(255,255,255,.3);
	font-size: 16px;
	color: #ffffff;
	font-family: $sans-font;
	align-items: center;
}

.c-tooltip {
	position: absolute;
	bottom: -38px;
	left: calc(50% - 43px);
	background: #457fc7;
	display: none;
	width: 86px;
	height: 30px;
	font-family: $sans-font;
	font-size: 14px;
	color: #ffcd51;
	text-transform: uppercase;
	font-weight: bold;
	align-items: center;
	justify-content: center;
	text-align: center;
	&:before {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 8px 6px 8px;
		border-color: transparent transparent #457fc7 transparent;
		position: absolute;
		top: -6px;
		left: calc(50% - 8px);
	}
}

.c-hm__addressIcon {
	position: relative;
	flex-shrink: 0;
	&:hover {
		.c-tooltip {
			display: flex;
		}
	}
}

.c-hm__officeAddress {
	display: flex;
	margin-right: 27px;
	align-items: center;
	.c-hm__addressIcon {
		margin-right: 20px;
		width: 25px;
		height: 24px;
		display: block;
		font-style: normal;
		background: url("../images/office-icon.png") no-repeat 0 0;
	}
}

.c-hm__skladAddress {
	display: flex;
	align-items: center;
	.c-hm__addressIcon {
		margin-right: 20px;
		width: 40px;
		height: 22px;
		display: block;
		font-style: normal;
		background: url("../images/sklad-icon.png") no-repeat 0 0;
	}
}

.hb {
	background: #457fc7;
	height: 64px;
	display: flex;
	align-items: center;
}

.c-headSearch {
	form {
		height: 40px;
		display: flex;
		align-items: center;
	}
	input[type="text"] {
		width: 848px;
		height: 40px;
		border: none;
		outline: none;
		padding: 0 30px;
		margin-right: 3px;
		&::-webkit-input-placeholder       {font-family: $sans-font; font-size: 16px; font-style: italic; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
		&::-moz-placeholder                {font-family: $sans-font; font-size: 16px; font-style: italic; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
		&:-moz-placeholder                 {font-family: $sans-font; font-size: 16px; font-style: italic; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
		&:-ms-input-placeholder            {font-family: $sans-font; font-size: 16px; font-style: italic; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
		&:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
		&:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
		&:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
		&:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
	}
	input[type="submit"] {
		width: 67px;
		height: 40px;
		border: none;
		background-color: #ffcd51;
		background-image: url('../images/search-icon.png');
		background-repeat: no-repeat;
		background-position: 50% 50%;
		outline: none;
		&:hover {
			background-color: #ffdc51;
		}
	}
}

.c-adp__button {
	font-family: $sans-font;
	display: none;
	width: 100%;
	height: 50px;
	border: none;
	outline: none;
	background: #316ab2;
	font-weight: bold;
	color: #ffcd51;
	text-transform: uppercase;
	font-size: 16px;
	position: relative;
	z-index: 10;
	& > span {
		position: relative;
		padding-left: 25px;
		line-height: 1;
		white-space: nowrap;
	}
	.nav-icon {
		width: 15px;
		height: 13px;
		position: absolute;
		left: 0;
		top: calc(50% - 5.5px);
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		span {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			background: #ffcd51;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
			&:nth-child(1) {
				top: 0px;
			}
			&:nth-child(2) {
				top: 5px;
			}
			&:nth-child(3) {
				top: 10px;
			}
		}
		&.open {
			span {
				&:nth-child(1) {
					top: 5px;
					transform: rotate(135deg);
				}
				&:nth-child(2) {
					opacity: 0;
					left: -60px;
				}
				&:nth-child(3) {
					top: 5px;
					transform: rotate(-135deg);
				}
			}
		}
	}
}

.c-adpCart {
	display: none;
	background: #457fc7;
	align-items: center;
	justify-content: center;
	padding: 14px 0;
	.c-icon {
		width: 27px;
		height: 25px;
		display: block;
		background: url("../images/cart-icon.png") no-repeat 0 0;
		background-size: cover;
		margin-right: 18px;
	}
	.c-headCart__info {
		span:first-child {
			margin-right: 12px;
		}
	}
}



