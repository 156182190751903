.c-news__item {
	display: flex;
	padding-bottom: 30px;
	border-bottom: 5px solid #e8ecf1;
	margin-bottom: 30px;
}

.c-news__img {
	flex-shrink: 0;
	margin-right: 30px;
	a {
		display: block;
		background: #316ab2;
		border-radius: 10px;
		overflow: hidden;
		text-decoration: none;
	}
	img {
		border-radius: 10px;
		overflow: hidden;
	}
}

.c-news__date {
	display: flex;
	height: 38px;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #ffcd51;
	font-weight: bold;
	font-size: 14px;
	font-family: $sans-font;
	span {
		position: relative;
		padding-left: 32px;
		line-height: 1;
		white-space: nowrap;
		&:before {
			content: '';
			position: absolute;
			display: block;
			width: 18px;
			height: 19px;
			background: url("../images/news-date.png") no-repeat 0 0;
			left: 0;
			top: calc(50% - 9.5px);
		}
	}
}

.c-news__right {
	position: relative;
	top: -0.25em;
}

.c-news__title {
	font-family: $sans-font;
	text-decoration: none;
	color: #32629e;
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 9px;
	display: inline-block;
	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #6199df;
	}
}

.c-news__anons {
	font-size: 14px;
	color: #3e4144;
	margin-bottom: 31px;
	line-height: 24px;
}

.c-newsPage__img {
	padding-bottom: 5px;
	background: #457fc7;
	border-radius: 9px;
	overflow: hidden;
	float: left;
	margin: 0 19px 20px 0;
	img {
		border-radius: 9px;
		overflow: hidden;
	}
}

.c-newsPage__date {
	margin-bottom: 17px;
	font-size: 14px;
	font-family: $sans-font;
	color: #316ab2;
	font-weight: bold;
	span {
		position: relative;
		padding-left: 32px;
		&:before {
			content: '';
			display: block;
			width: 18px;
			height: 18px;
			position: absolute;
			left: 0;
			top: calc(50% - 9.5px);
			background: url("../images/date-icon--blue.png") no-repeat 0 0;
		}
	}
}