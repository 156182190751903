.box-modal {
	background: #ffffff;
	padding: 50px 40px 40px;
	width: 440px;
	position: relative;
	font-family: $sans-font;
	.c-pin {
		margin-top: -10px;
		margin-bottom: 30px;
	}
	&#pas {
		.field--inp {
			margin-bottom: 30px;
		}
	}
	.help-block {
		position: absolute;
		color: #de413b;
		font-size: 14px;
		margin: 0;
		width: 100%;
		right: 0;
		top: -17px;
		max-width: 100%;
		left: initial;
		text-align: right;
	}
}

.box-modal_close {
	width: 16px;
	height: 18px;
	display: block;
	position: absolute;
	top: 25px;
	right: 25px;
	background: url("../images/modal-close.png") no-repeat 0 0;
	cursor: pointer;
	&:hover {
		background-position: 0 -18px;
	}
}

.c-modal__title {
	margin: 0 0 30px;
	font-size: 24px;
	font-weight: bold;
	color: #302f2f;
}

.field--inp {
	margin-bottom: 20px;
	position: relative;
}

.field--modal {
	margin: 30px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.forbut {
	input, button {
		outline: none;
	}
}