.c-searchSelect {
	width: 222px;
	height: 40px;
	visibility: hidden;
	& + .select2 {
		* {
			outline: none !important;
		}
		.select2-selection {
			height: 40px;
			border-radius: 0;
			border: none;
			.select2-selection__rendered {
				height: 40px;
				display: flex;
				align-items: center;
				font-family: $sans-font;
				font-size: 16px;
				font-style: italic;
				color: #505356;
				padding: 0 45px 0 20px;
			}
			.select2-selection__arrow {
				height: 40px;
				top: 0;
				right: 0;
				width: 45px;
				b {
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 7px 6.5px 0 6.5px;
					border-color: #999999 transparent transparent transparent;
					margin-left: -6px;
					margin-top: -3px;
				}
				&:hover {
					b {
						border-color: #555759 transparent transparent transparent;
					}
				}
			}
		}
	}
}

.select2-container {
	.select2-dropdown {
		background-color: #f7f9fb;
		border: 1px solid #c3c7cd;
		border-radius: 0;
		border-top: none;
		padding: 12px 0;
		.select2-results__option {
			padding: 7px 10px 7px 19px;
			font-family: $sans-font;
			font-size: 14px;
			color: #3e4144;
		}
		.select2-results__option[aria-selected=true],
		.select2-results__option--highlighted {
			background: #dce2ea;
		}
	}
}

.c-customSelect {
	width: 100%;
	height: 40px;
	visibility: hidden;
	& + .select2 {
		* {
			outline: none !important;
		}
		.select2-selection {
			height: 40px;
			border-radius: 0;
			border: 1px solid #d4d9e0;
			background: #f1f4f7;
			.select2-selection__rendered {
				height: 40px;
				display: flex;
				align-items: center;
				font-family: $sans-font;
				font-size: 14px;
				color: #505356;
				padding: 0 45px 0 20px;
			}
			.select2-selection__arrow {
				height: 40px;
				top: 0;
				right: 0;
				width: 45px;
				b {
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 7px 6.5px 0 6.5px;
					border-color: #999999 transparent transparent transparent;
					margin-left: -6px;
					margin-top: -3px;
				}
				&:hover {
					b {
						border-color: #555759 transparent transparent transparent;
					}
				}
			}
		}
	}
}
