footer {
	background: #e8ecf1;
}

#up {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 243px;
	margin-left: 1175px;
	background-color: #dce2ea;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		
		width: 31px;
		color: #505356;
		height: 20px;
		display: block;
		content: '';
		background-image: url("../images/up-icon.png");
		background-repeat: no-repeat;
		background-position: 0 0;
	}
	&:hover {
		&:before {
			background-position: 0 -20px;
		}
	}
}

.c-footNav {
	background: #316ab2;
	ul {
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		height: 50px;
		align-items: center;
		margin: 0;
	}
	li.active {
		a {
			font-weight: bold;
			color: #ffcd51;
		}
	}
	a {
		font-size: 16px;
		font-family: $sans-font;
		color: #fff;
		text-decoration: none;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #ffcd51;
		}
	}
}

.fm {
	padding: 28px 0 34px;
}

.c-copy {
	font-size: 14px;
	color: #3e4144;
	p {
		line-height: 1.2;
	}
}

.l-footAddress {
	font-size: 14px;
	color: #3e4144;
	& > div {
		margin-bottom: 13px;
		display: flex;
		span {
			flex-shrink: 0;
			width: 68px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.l-footContacts {
	font-size: 14px;
	color: #3e4144;
	& > div {
		margin-bottom: 13px;
		display: flex;
		span {
			flex-shrink: 0;
			width: 68px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.fb {
	padding-bottom: 30px;
}

.l-footSearch {
	form {
		display: flex;
	}
	input[type="text"] {
		width: calc(100% - 69px);
		height: 40px;
		background: #fff;
		border: 1px solid #c3c7cd;
		border-right: none;
		outline: none;
		padding: 0 18px;
		font-size: 16px;
		color: #505356;
		&::-webkit-input-placeholder       {color: #505356; font-family: $sans-font; font-style: italic; opacity: 1; transition: opacity 0.3s ease;}
		&::-moz-placeholder                {color: #505356; font-family: $sans-font; font-style: italic; opacity: 1; transition: opacity 0.3s ease;}
		&:-moz-placeholder                 {color: #505356; font-family: $sans-font; font-style: italic; opacity: 1; transition: opacity 0.3s ease;}
		&:-ms-input-placeholder            {color: #505356; font-family: $sans-font; font-style: italic; opacity: 1; transition: opacity 0.3s ease;}
		&:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
		&:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
		&:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
		&:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
	}
	input[type="submit"] {
		width: 69px;
		border: none;
		outline: none;
		height: 40px;
		background-color: #316ab2;
		background-image: url('../images/search-icon--yellow.png');
		background-position: 50% 50%;
		background-repeat: no-repeat;
		&:hover {
			background-color: #6199df;
		}
	}
}

.l-footDevelop {
	text-align: right;
	a {
		display: inline-block;
		text-decoration: none;
		color: #3e4144;
		font-size: 14px;
		font-family: $sans-font;
		text-align: left;
	}
}