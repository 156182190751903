.c-cartTable {
	margin-top: 30px;
	margin-bottom: 37px;
	&--nomg {
		margin: 0;
	}
	table {
		width: 100%;
		border-collapse: separate;
		table-layout: fixed;
		border-spacing: 0 2px;
		thead {
			background: #457fc7;
			color: #ffffff;
			th {
				font-size: 14px;
				font-family: $sans-font;
				height: 57px;
				font-weight: normal;
				text-align: center;
				&.tc3 {
					text-align: left;
					padding-left: 20px;
				}
			}
		}
		tbody {
			tr {
				&:hover {
					& > td:not(.tc7) {
						background: #fffaed;
					}
				}
			}
			td {
				border: 1px solid #d4d9e0;
				border-right: none;
				background: #ffffff;
				&.tc1 {
					text-align: center;
					font-size: 14px;
					font-family: $sans-font;
					color: #6b6c72;
				}
				&.tc2 {
					text-align: center;
				}
				&.tc3 {
					padding: 0 20px;
					a {
						font-family: $sans-font;
						color: #457fc7;
						font-size: 14px;
						text-decoration: underline;
						&:hover, &:active, &:focus {
							text-decoration: none;
						}
					}
				}
				&.tc4, &.tc5, &.tc6, &.tc8 {
					text-align: center;
					font-size: 14px;
					color: #6b6c72;
					font-family: $sans-font;
				}
				&.tc7 {
					padding: 0;
					background: #f1f4f7;
					input {
						background: transparent;
						width: 100%;
						height: 49px;
						border: none;
						outline: none;
						text-align: center;
						color: #8a8b93;
						padding-left: 10px;
						font-family: $sans-font;
						font-size: 14px;
						&:focus {
							color: #3e4144;
						}
					}
				}
				&.tc9 {
					height: 51px;
				}
			}
		}
	}
	.c-cartDel {
		border: none;
		outline: none;
		width: 40px;
		display: block;
		height: 51px;
		background-color: #457fc7;
		background: url("../images/cart-del.png") no-repeat 0 0;
		&:hover {
			background-color: #ffcd51;
			background-position: 0 -51px;
		}
	}
	.tc1 {
		width: 38px;
	}
	.tc2 {
		width: 39px;
	}
	.tc3 {
		width: 165px;
	}
	.tc4 {
		width: 135px;
	}
	.tc5 {
		width: 98px;
	}
	.tc6 {
		width: 113px;
	}
	.tc7 {
		width: 98px;
	}
	.tc8 {
		width: 113px;
	}
	.tc9 {
		width: 40px;
	}
}

.c-cartTable__foot {
	margin-top: 8px;
	background: #e8ecf1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	position: relative;
	&--right {
		justify-content: flex-end;
	}
}

.c-cartTable__foot-links {
	a {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
		font-family: $sans-font;
		color: #316ab2;
		text-decoration: none;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #6199df;
		}
		&:first-child {
			padding-left: 70px;
			&:before {
				content: '';
				display: block;
				width: 50px;
				height: 50px;
				position: absolute;
				left: 0;
				top: 0;
				background-color: #457fc7;
				background-image: url('../images/backlink.png');
				background-repeat: no-repeat;
				background-position: 50% 50%;
			}
			&:hover {
				&:before {
					background-color: #6199df;
				}
			}
		}
	}
	.sep {
		margin: 0 7px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: bold;
		font-family: $sans-font;
		color: #316ab2;
	}
}

.c-cartTable__foot-sum {
	line-height: 1;
	white-space: nowrap;
	font-family: $sans-font;
	color: #316ab2;
	font-weight: bold;
	font-size: 16px;
	padding-right: 40px;
	span {
		font-size: 14px;
		font-weight: normal;
		margin-left: 6px;
	}
}

.c-cartBlc {
	border: 5px solid #e8ecf1;
	border-bottom: 40px solid #e8ecf1;
	position: relative;
	padding: 31px 29px 39px;
	margin-bottom:  37px;
	&:last-child {
		margin-bottom: 0;
	}
	&--mg {
		margin-top: 27px;
	}
	&--shipping {
		.field {
			width: 423px;
			display: flex;
			align-items: center;
			.lbl {
				width: 63px;
				flex-shrink: 0;
			}
		}
		.pcheck {
			margin-top: 25px;
			.lbl {
				margin-bottom: 14px;
			}
		}
	}
	&--contens {
		.field {
			width: 423px;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			.lbl {
				width: 157px;
				flex-shrink: 0;
				padding-right: 12px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	form + p {
		margin-top: 20px;
		color: #316ab2;
	}
}

.c-cartBlc__text {
	font-size: 14px;
	color: #3e4144;
	margin-bottom: 27px;
	span {
		color: #316ab2;
	}
}

.l-shippingTable {
	margin-top: 29px;
	display: none;
	width: 505px;
	font-family: "PT Sans", Arial, sans-serif;
	&.on {
		display: block;
	}
	table {
		border-collapse: separate;
		border-spacing: 2px;
		width: 100%;
		table-layout: fixed;
	}
	thead {
		th {
			background: #e8ecf1;
			height: 51px;
			font-size: 16px;
			font-weight: normal;
			color: #3e4144;
			line-height: 18px;
			&.tc2 {
				padding-left: 20px;
			}
		}
	}
	tbody {
		tr {
			td {
				background: #f1f4f7;
				font-size: 16px;
				color: #3e4144;
				line-height: 18px;
			}
			&:nth-child(even) {
				td {
					background: #e8ecf1;
				}
			}
		}
	}
	.tc1 {
		width: 45px;
		text-align: center;
		label {
			padding-left: 16px;
			height: 16px;
		}
	}
	.tc2 {
		width: 273px;
		padding: 10px 20px 10px 20px;
	}
	.tc3 {
		width: 77px;
		text-align: center;
		
	}
	.tc4 {
		width: 104px;
		text-align: center;
	}
	.w-shippingTable__name {
		display: flex;
		align-items: center;
	}
	.c-shippingTable__img {
		width: 70px;
		background-size: contain;
		background-position: 50% 50%;
		height: 70px;
		text-align: center;
		margin-right: 20px;
		background-repeat: no-repeat;
	}
	.c-shippingTable__name {
		flex-shrink: 0;
	}
}

.c-cartBlc__top {
	padding: 0 29px;
	padding-bottom: 30px;
	border-bottom: 5px solid #e8ecf1;
	margin: 0 -29px;
}

.c-cartBlc__bottom {
	padding: 0 29px;
	padding-top: 30px;
	margin: 0 -29px;
	&--nopd {
		padding-top: 0;
	}
}

.l-cart {
	#BlockInputTrKom {
		margin-top: 20px;
	}
	.BlockInputSityTrKom {
		margin: 0;
		float: none;
	}
	.l-shippingTable {
		margin-top: 20px;
	}
	#deliveryResultBlock {
		margin: 0;
		width: 505px;
	}
	#ULcityDELIVERY {
		width: 360px;
		z-index: 100;
	}
	.LIcityDELIVERY {
		padding: 4px 19px;
		&:hover {
			background: #dce2ea;
			padding: 4px 19px;
		}
	}
	.selectedCity {
		padding: 4px 19px;
		background: #dce2ea;
	}
}

.orderBlock {
	margin-top: 30px;
}

.form-cart-bottom {
	margin-top: -35px;
	margin-bottom: 30px;
	background: #e8ecf1;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: flex-end;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 50px;
	position: relative;
	padding-right: 40px;
	line-height: 1;
	white-space: nowrap;
	font-weight: bold;
	color: #316ab2;
	font-size: 16px;
	span {
		font-weight: normal;
		margin-left: 5px;
	}
}