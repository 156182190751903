@media all and (min-width: 480px) and (max-width: 767px) {
	.l-headAuth {
		display: flex;
		justify-content: space-between;
	}
	.c-headAuth__link {
		width: 50%;
		max-width: none;
		flex-shrink: 0;
		margin: 0;
		height: 55px;
	}
	.c-adpCart {
		height: 55px;
		padding: 0;
		width: 50%;
		max-width: none;
		justify-content: flex-end;
		padding-right: 30px;
		.c-headCart__info {
			font-size: 12px;
			span {
				line-height: 1;
				white-space: nowrap;
				display: block;
				&:first-child {
					margin-right: 0;
					margin-bottom: 4px;
				}
			}
		}
	}
	.c-headAuth__link--false a:first-child:before, 
	.c-headAuth__link--true a:first-child:before {
		height: 55px;
		width: 60px;
	}
	.c-hm__phones {
		padding-right: 45px;
		width: calc(100% - 150px);
		font-size: 14px;
		justify-content: flex-start;
		& > div:first-child {
			margin-right: 22px;
		}
	}
	.c-hm__mail {
		display: block;
		font-size: 14px;
	}
	.l-hm__top {
		display: flex;
		flex-flow: row wrap;
	}
	.l-hm__bottom {
		font-size: 14px;
		text-align: center;
	}
	.c-hm__officeAddress {
		padding-left: 0;
		text-align: center;
		justify-content: center;
	}
	.c-hm__skladAddress {
		text-align: center;
		justify-content: center;
	}
	.l-catItem {
		width: 50%;
		&:nth-child(odd) {
			text-align: right;
		}
		&:nth-child(even) {
			text-align: left;
		}
	}
	.c-catItem {
		margin: 0;
		display: inline-block;
	}
	.c-homeSlider {
		width: 372px;
		.slick-next {
			right: -39px;
		}
		.slick-prev {
			left: -39px;
		}
	}
	.c-homeExc .c-title {
		max-width: 362px;
	}
	.c-homeExc__item {
		width: 50%;
	}
	.l-footCounters {
		width: 50%;
		text-align: left;
		float: left;
		margin-bottom: 0;
	}
	.l-footDevelop {
		width: 50%;
		text-align: right;
		float: left;
		a {
			br {
				display: initial;
			}
		}
	}
}