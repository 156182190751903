blockquote {
	color: #3e4144;
	font-size: 14px;
	line-height: 24px;
	font-family: Arial, sans-serif;
	background: #f1f4f7;
	border-left: none;
	padding: 24px 53px 34px;
	font-style: italic;
}

.pintable {
	border-collapse: separate;
	border-spacing: 0 2px;
	th {
		background: #457fc7;
		color: #ffffff;
		padding: 10px 0;
		font-weight: normal;
	}
	td {
		border: 1px solid #d4d9e0;
		padding: 10px 0;
		border-left: none;
		&:first-child {
			border-left: 1px solid #d4d9e0;
		}
	}
}

.c-pin {
	& + .l-news {
		margin-top: 40px;
	}
}