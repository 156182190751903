@media all and (min-width: 768px) and (max-width: 991px) {
	.c-homeSlider {
		width: 599px;
	}
	.l-headCart {
		display: none;
	}
	.ht {
		& > .container {
			position: relative;
		}
	}
	.l-headAuth {
		position: absolute;
		top: 50px;
		z-index: 10;
		width: 100%;
	}
	.l-headAuth {
		display: flex;
		justify-content: space-between;
	}
	.c-headAuth__link {
		width: 50%;
		max-width: none;
		flex-shrink: 0;
		margin: 0;
		height: 55px;
	}
	.c-adpCart {
		display: flex;
		height: 55px;
		padding: 0;
		width: 50%;
		max-width: none;
		justify-content: flex-end;
		padding-right: 30px;
		background: #316ab2;
		.c-headCart__info {
			font-size: 14px;
			span {
				line-height: 1;
				white-space: nowrap;
				&:first-child {
					margin-right: 12px;
				}
			}
		}
	}
	.c-headAuth__link--false a:first-child:before,
	.c-headAuth__link--true a:first-child:before {
		height: 55px;
		width: 60px;
	}
	.l-navMenu ul a {
		font-size: 14px;
	}
	.c-dropMenu__link a {
		font-size: 14px;
	}
	.l-hm__left {
		position: relative;
	}
	.l-hm__left {
		padding-top: 89px;
	}
	.c-logo {
		width: 320px;
		flex-shrink: 0;
		margin-right: 66px;
	}
	.c-hm__phones {
		padding-left: 65px;
		font-size: 14px;
		margin-right: 0;
	}
	.c-hm__mail {
		display: none;
	}
	.l-hm__bottom {
		font-size: 14px;
		padding: 0 20px;
	}
	.c-hm__officeAddress {
		margin-right: 20px;
	}
	.c-hm__skladAddress .c-hm__addressIcon,
	.c-hm__officeAddress .c-hm__addressIcon {
		margin-right: 14px;
	}
	.c-headSlider {
		.c-slide__title {
			font-size: 30px;
			margin: 0 0 11px;
		}
		.c-slide {
			padding-top: 276px;
		}
		.c-slide__text {
			font-size: 14px;
			line-height: 20px;
		}
	}
	.c-slide__button {
		.c-button {
			height: 51px;
			font-size: 16px;
		}
	}
	.c-headSlider .dots-container {
		top: -42px;
	}
	.c-headSearch input[type=text] {
		width: 446px;
	}
	.l-catItem {
		width: 25%;
	}
	.c-catItem__name {
		font-size: 12px;
	}
	.c-catItem__links a {
		font-size: 12px;
	}
	.c-homeExc__item {
		width: 33.333333%;
	}
	.c-homeExc__text {
		font-size: 14px;
	}
	.c-button {
		width: 220px;
		height: 51px;
		font-size: 16px;
	}
	.c-footNav a {
		font-size: 14px;
	}
	.c-copy, .l-footAddress, .l-footContacts {
		font-size: 12px;
	}
	footer {
		position: relative;
		padding-bottom: 50px;
		& > .container {
			padding: 0;
		}
	}
	#up {
		position: absolute;
		bottom: 0;
		margin-left: 0;
		height: 50px;
		width: 100%;
		left: 0;
	}
	.c-filter {
		margin-top: -20px;
		margin-bottom: 30px;
	}
	.l-lkNav {
		margin-top: 30px;
	}
}