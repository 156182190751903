.l-sidebar {
	z-index: 101;
}

.c-sidebarNav {
	ul {
		margin: 0;
		list-style-type: none;
		& > li {
			
		}
	}
	li {
		position: relative;
		&.active {
			& > a {
				color: #ffcd51;
				background: #457fc7;
				border-color: #457fc7
			}
		}
		&:last-child {
			a {
				margin-bottom: 0;
			}
		}
		&:hover {
			& > a {
				color: #ffcd51;
				background: #457fc7;
				border-color: #457fc7
			}
		}
	}
	a {
		padding: 11px 15px 10px 33px;
		display: block;
		background: #e8ecf1;
		color: #32629e;
		font-size: 16px;
		font-family: $sans-font;
		border: 1px solid #d4d9e0;
		border-left: none;
		text-decoration: none;
		margin-bottom: 2px;
		position: relative;
		&:before {
			content: '';
			height: calc(100% + 2px);
			width: 18px;
			display: block;
			background: #457fc7;
			position: absolute;
			left: 0;
			top: -1px;
			
		}
		&:hover, &:active, &:focus {
			color: #32629e;
			text-decoration: none;
		}
	}
	& > ul {
		ul {
			background: transparent;
			width: 248px;
			padding-left: 2px;
			z-index: 100;
		}
		& > li {
			&:hover {
				& > ul {
					display: block;
				}
			}
			& > ul {
				display: none;
				position: absolute;
				left: 262.5px;
				top: 0;
				li {
					a {
						padding-left: 23px;
						&:before {
							width: 8px;
						}
					}
				}
				& > li {
					&:hover {
						& > ul {
							display: block;
						}
					}
					& > ul {
						display: none;
						position: absolute;
						left: 246px;
						top: 0;
						& > li {
							&:hover {
								& > ul {
									display: block;
								}
							}
							& > ul {
								display: none;
								position: absolute;
								left: 246px;
								top: 0;
							}
						}
					}
				}
			}
		}
	}
}

.c-sidebarNavAdp {
	display: none;
	position: relative;
	margin-bottom: 30px;
	max-width: 290px;
}

.c-sidebarNavAdp__button {
	width: 290px;
	height: 42px;
	border: 1px solid #d4d9e0;
	border-left: none;
	padding-left: 38px;
	position: relative;
	font-size: 16px;
	text-transform: uppercase;
	font-family: $sans-font;
	font-weight: bold;
	color: #32629e;
	outline: none;
	text-align: left;
	&:before {
		content: '';
		display: block;
		width: 18px;
		height: 42px;
		position: absolute;
		left: 0px;
		top: -1px;
		background: #457fc7;
	}
	&:after {
		content: '';
		position: absolute;
		width: 18px;
		height: 12px;
		top: calc(50% - 6px);
		right: 19px;
		background: url("../images/sidebar-adp.png") no-repeat 0 0;
	}
}

.w-sidebarNavAdp {
	display: none;
	position: absolute;
	top: 42px;
	z-index: 1000;
	left: 0;
	width: 100%;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		border: 1px solid #d4d9e0;
		border-top: none;
		border-left: 18px solid #457fc7;
		background: #e8ecf1;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 18px;
			background: #457fc7;
			left: -18px;
			height: 2px;
			top: -1px;
		}
	}
	li {
		&:last-child {
			a {
				border-bottom: none;
			}
		}
	}
	a {
		padding: 10px 33px 10px 15px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #d4d9e0;
		font-size: 16px;
		text-decoration: none;
		color: #32629e;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #32629e;
		}
	}
}