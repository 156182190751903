.c-customCheckbox {
	position: absolute;
	visibility: hidden;
	& + label {
		font-weight: normal;
		margin-bottom: 0;
		position: relative;
		padding-left: 33px;
		font-size: 14px;
		color: #3e4144;
		line-height: 1;
		white-space: nowrap;
		cursor: pointer;
		&:before {
			content: '';
			display: block;
			width: 18px;
			height: 18px;
			border-radius: 2px;
			border: 1px solid #b8bcc2;
			position: absolute;
			left: 0;
			top: calc(50% - 9px);
		}
	}
	&:checked {
		& + label {
			&:after {
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				position: absolute;
				left: 0;
				top: calc(50% - 9px);
				background: url("../images/c-check.png") no-repeat 0 0;
			}
		}
	}
}