.c-filter {
	font-family: $sans-font;
	margin-top: 10px;
}

.c-filter__title {
	margin-bottom: 2px;
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #fff;
	font-weight: bold;
	font-size: 16px;
	background: url("../images/filter-bg.png");
	text-transform: uppercase;
}

.c-filterButtons {
	background: #ffcd51;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 50px;
	margin-bottom: 2px;
	&:hover {
		background: #ffdc51;
	}
	&:last-child {
		margin-bottom: 0;
	}
	a, input {
		color: #316ab2;
		text-decoration: none;
		text-transform: uppercase;
		background: transparent;
		border: none;
		outline: none;
		&:first-child {
			padding-left: 27px;
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 13px;
				height: 18px;
				position: absolute;
				left: 0;
				top: calc(50% - 9px);
				background: url("../images/filter-clean.png") no-repeat 0 0;
			}
		}
		&:hover {
			text-decoration: none;
			color: #457fc7;
			&:before {
				background-position: 0 -18px;
			}
		}
	}
	.sep {
		margin: 0 8px;
		font-weight: bold;
		color: #316ab2;
	}
}

.c-filterBlock {
	margin-bottom: 2px;
	background: url("../images/filter-bg.png");
	position: relative;
	padding: 20px 14.5px 30px 20px;
	border-right: 5px solid #457fc7;
	&.on {
		.c-filterBlock__content {
			display: block;
		}
		.c-filter__open {
			top: 0;
			bottom: initial;
			&:before {
				transform: rotate(180deg);
			}
		}
	}
	.c-customSelect {
		& + .select2 {
			width: 100% !important;
			.select2-selection {
				height: 30px;
				.select2-selection__rendered {
					height: 30px;
				}
				.select2-selection__arrow {
					height: 30px;
				}
			}
		}
	}
}

.c-filter__open {
	position: absolute;
	bottom: 0;
	top: initial;
	right: -5px;
	border: none;
	outline: none;
	width: 30px;
	height: 30px;
	background: #457fc7;
	&:before {
		content: '';
		display: block;
		width: 15px;
		height: 9px;
		background: url("../images/filter-arrow.png") no-repeat 0 0;
		margin: 0 auto;
	}
	&:hover {
		background: #6199df;
	}
}

.c-filterBlock__label {
	cursor: pointer;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	&:hover {
		color: #ffdc51;
	} 
}

.c-filterBlock__content {
	display: none;
	margin-top: 20px;
}

.c-sliderRange__amount {
	display: flex;
	margin-bottom: 20px;
	justify-content: space-between;
	& > div {
		width: 103px;
	}
	label {
		color: #ebeef4;
		font-size: 14px;
		margin-bottom: 0;
		line-height: 1;
		white-space: nowrap;
		font-weight: normal;
		margin-right: 10px;
	}
	input {
		width: 77px;
		height: 20px;
		text-align: center;
		outline: none;
		background: #f1f4f7;
		border: 1px solid #d4d9e0;
		padding-left: 10px;
		color: #8a8b93;
	}
}

.c-sliderRange__min {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.c-sliderRange {
	height: 5px;
	border-radius: 0;
	border: none;
	.ui-slider-range {
		border-radius: 0;
		background-color: #ffcd51;
	}
	.ui-state-focus {
		outline: none;
	}
	.ui-slider-handle {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: none;
		background: #ffcd51;
		top: calc(50% - 8px);
	}
}
