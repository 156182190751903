body.home {
	.l-slider {
		display: block;
	}
}

.l-slider {
	display: none;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
}

.slick-slider {
	*:focus {
		outline: none;
	}
}

.c-headSlider {
	height: 469px;
	overflow: hidden;
	.c-slide {
		padding-top: 218px;
		height: 469px;
		background-position: 50% 50%;
		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		& > .container {
			position: relative;
			z-index: 3;
		}
		.row {
			display: flex;
		}
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			display: block;
			width: 100%;
			height: 469px;
			background: rgba(0, 0, 0, .25);
		}
	}
	.c-slide__title {
		font-family: $sans-font;
		font-size: 48px;
		font-weight: bold;
		color: #ffffff;
		margin: 0 0 24px
	}
	.c-slide__text {
		font-family: $main-font;
		color: #fff;
		line-height: 24px;
		font-size: 16px;
		padding-right: 10px;
	}
	.c-slide__button {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.dots-container {
		position: relative;
		top: -72px;
	}
	.slick-dots {
		margin: 0;
		list-style-type: none;
		display: flex;
		li {
			margin-right: 14px;
			&:last-child {
				margin-right: 0;
			}
			&.slick-active {
				button {
					background: #ffcd51;
				}
			}
		}
		button {
			border: none;
			outline: none;
			line-height: 0;
			font-size: 0;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #fff;
			&:hover {
				background: #ffcd51;
			}
		}
	}
}

.c-homeSlider {
	width: 1006px;
	margin: 0 auto;
	.slick-list {
		margin: 0 -5px;
	}
	.c-slide {
		margin: 0 5px;
		position: relative;
		border-radius: 9px;
		overflow: hidden;
		&:hover {
			.c-slide__top {
				border-color: #457fc7;
			}
		}
		&--new {
			.c-slide__top {
				border-top-right-radius: 11px;
			}
			&:after {
				content: '';
				width: 69px;
				height: 69px;
				display: block;
				top: 0;
				right: 0;
				position: absolute;
				z-index: 3;
				background: url("../images/new-tooltip.png") no-repeat 0 0;
			}
		}
		&--sale {
			.c-slide__top {
				border-top-right-radius: 11px;
			}
			&:after {
				content: '';
				width: 69px;
				height: 69px;
				display: block;
				top: 0;
				right: 0;
				position: absolute;
				z-index: 3;
				background: url("../images/sale-tooltip.png") no-repeat 0 0;
			}
		}
	}
	.c-slide__top {
		background: #ffffff;
		border-top-left-radius: 9px;
		border-top-right-radius: 9px;
		border: 1px solid #d4d9e0;
		overflow: hidden;
		border-bottom: none;
		hr {
			width: 152px;
			margin: 0 auto 14px;
			border-color: #d4d9e0;
		}
	}
	.c-slide__img {
		height: 199px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: #fff;
	}
	.c-slide__name {
		height: 51px;
		text-align: center;
		padding: 0 15px;
		a {
			font-family: $sans-font;
			font-size: 14px;
			font-weight: bold;
			color: #32629e;
			text-decoration: none;
			line-height: 14px;
			display: inline-block;
			&:hover, &:active, &:focus {
				text-decoration: none;
				color: #457fc7;
			}
		}
	}
	.c-slide__price {
		text-align: center;
		height: 53px;
		font-family: $sans-font;
		font-size: 14px;
		line-height: 1;
		white-space: nowrap;
		&--pre {
			color: #787e84;
			text-decoration: line-through;
		}
		&--cur {
			color: #3e4144;
			padding-top: 9px;
			b {
				font-size: 20px;
			}
		}
	}
	.c-slide__price--pre + .c-slide__price--cur {
		padding-top: 3px;
	}
	.c-slide__button {
		height: 50px;
		border: none;
		outline: none;
		background: #316ab2;
		width: 100%;
		border-bottom-left-radius: 9px;
		border-bottom-right-radius: 9px;
		overflow: hidden;
		span {
			position: relative;
			font-family: $sans-font;
			font-weight: bold;
			font-size: 14px;
			color: #fff;
			padding-left: 57px;
			text-transform: uppercase;
			&:before {
				content: '';
				display: block;
				width: 39px;
				height: 25px;
				position: absolute;
				left: 0;
				top: calc(50% - 12.5px);
				background: url("../images/button-cart-icon.png") no-repeat 0 0;
			}
		}
		&:hover {
			background: #457fc7;
			span {
				&:before {
					background-position: 0 -25px;
				}
			}
		}
	}
	.slick-arrow {
		width: 39px;
		height: 60px;
		border: none;
		outline: none;
		background: url("../images/slider-arrow.png") no-repeat 0 0;
		position: absolute;
		top: calc(50% - 30px);
		&:hover {
			background-position-y: 0;
		}
	}
	.slick-prev {
		left: -67px;
		background-position-y: -60px;
	}
	.slick-next {
		right: -67px;
		background-position-x: -39px;
		background-position-y: -60px;
	}
}