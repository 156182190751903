.l-pageContent {
	min-height: calc(100vh - 544px);
	padding-bottom: 60px;
}

.c-page-title {
	margin: 0 0 30px;
	font-family: $sans-font;
	font-weight: bold;
	color: #3e4144;
	font-size: 24px;
	position: relative;
	&--link {
		margin: 0 0 18px;
		a:not(.for-print) {
			margin-left: 11px;
			width: 17px;
			height: 27px;
			display: inline-block;
			background: url("../images/arrow-back.png") no-repeat 0 0;
			vertical-align: middle;
			margin-bottom: 3px;
			&:hover {
				background-position: 0 -27px;
			}
		}
	}
	.for-print {
		position: absolute;
		right: 0;
		top: 5px;
		color: #32629e;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		text-decoration: none;
		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #457fc7;
			span:before {
				background-position: 0 -30px;
			}
		}
		span {
			position: relative;
			padding-left: 38px;
			line-height: 1;
			white-space: nowrap;
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: calc(50% - 15px);
				width: 27px;
				height: 30px;
				background: url("../images/print-icon.png") no-repeat 0 0;
			}
		}
	}
}