@media all and (min-width: 320px) and (max-width: 767px) {
	.l-dropMenu {
		display: none;
	}
	.l-navMenu {
		padding: 0;
		nav {
			display: none;
			position: absolute;
			top: 50px;
			left: 0;
			z-index: 10;
			width: 100%;
			background: #457fc7;
		}
		ul {
			display: block;
			height: auto;
			border-bottom: 6px solid #316ab2;
			li {
				border-bottom: 1px solid #316ab2;
				&:last-child {
					border-bottom: none;
				}
				a {
					display: flex;
					height: 59px;
					align-items: center;
					padding-left: 38px;
				}
			}
		}
	}
	.c-adp__button {
		display: block;
	}
	.c-adpCart {
		display: flex;
		max-width: 290px;
		margin: 0 auto;
	}
	body.home .l-slider {
		display: none;
	}
	.ht, .hm {
		background: transparent;
	}
	header {
		background: url("../images/adp-head-bg.jpg");
	}
	.c-headAuth__link {
		background: #457fc7;
		max-width: 290px;
		margin: 0 auto;
	}
	.c-hm__mail {
		display: none;
	}
	.l-headCart {
		display: none;
	}
	.l-hm__top {
		display: block;
	}
	.c-logo {
		max-width: 320px;
		margin: 0 auto 30px;
	}
	.c-hm__phones {
		border-left: none;
		padding-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.l-hm__bottom {
		display: block;
		height: auto;
		padding: 10px 36px;
		font-size: 12px;
	}
	.c-hm__officeAddress {
		margin-right: 0;
		margin-bottom: 20px;
		padding-left: 15px;
	}
	body.home .hm {
		height: auto;
		padding-bottom: 35px;
	}
	.c-headSearch {
		form {
			flex-flow: row wrap;
			height: auto;
		}
		input[type=text] {
			width: 100%;
			margin-right: 0;
			margin-bottom: 3px;
		}
		.c-searchSelect {
			& + .select2 {
				width: calc(100% - 67px) !important;
			}
		}
	}
	.hb {
		height: auto;
		padding: 12px 0;
		& > .container {
			width: 100%;
		}
	}
	.c-footNav {
		display: none;
	}
	.l-catItem {
		width: 100%;
	}
	.c-catItem {
		max-width: 220px;
		margin: 0 auto;
	}
	.c-homeSlider {
		width: 193px;
		.slick-arrow {
			width: 27px;
			height: 41px;
			background-size: cover;
			background: url("../images/slider-arrow--adp.png") no-repeat 0 0;
			&:hover {
				background-position-x: 0;
			}
		}
		.slick-next {
			right: -49px;
			background-position-y: -41px;
			&:hover {
				background-position-y: -41px;
			}
		}
		.slick-prev {
			left: -49px;
		}
	}
	.c-homeExc {
		padding: 21px 35px 43px;
		.c-title {
			white-space: normal;
			font-size: 18px;
			left: 21px;
			max-width: 238px;
			text-align: center;
		}
	}
	.c-homeExc__item {
		width: 100%;
		margin-top: 40px;
	}
	.l-homeSeotext {
		font-size: 14px;
		.c-title {
			font-size: 18px;
		}
	}
	.c-button {
		width: 220px;
		height: 51px;
		font-size: 16px;
	}
	footer {
		border-top: 20px solid #316ab2;
		position: relative;
		padding-bottom: 50px;
		& > .container {
			padding: 0;
		}
	}
	.l-copy {
		margin-bottom: 24px;
	}
	.c-copy {
		text-align: center;
	}
	.l-footAddress {
		margin-bottom: 24px;
		text-align: center;
		& > div {
			flex-direction: column;
			& > span {
				justify-content: center;
				width: 100%;
				text-align: center;
				display: block;
				margin-bottom: 4px;
			}
		}
	}
	.l-footContacts {
		& > div:last-child {
			justify-content: center;
		}
	}
	.fm {
		padding: 28px 0 25px;
	}
	.l-footSearch {
		margin-bottom: 30px;
	}
	.l-footCounters {
		text-align: center;
		margin-bottom: 30px;
	}
	.l-footDevelop {
		text-align: center;
		a {
			br {
				display: none;
			}
		}
	}
	#up {
		position: absolute;
		bottom: 0;
		margin-left: 0;
		height: 50px;
		width: 100%;
		left: 0;
	}
	.c-breadcrumbs {
		padding: 20px 0;
	}
	.captcha {
		position: relative;
		height: 61.58px;
		#captcha {
			position: absolute;
			top: -7px;
			left: -32px;
			transform: scale(.78948);
		}
	}
	.c-cartBlc {
		.c-button {
			width: 100%;
			max-width: 240px;
			margin: 0 auto;
		}
	}
	.l-catPage__item {
		width: 100%;
	}
	.c-catPage__item {
		display: block;
		padding: 28px 25px 24px 25px;
	}
	.c-catPage__img {
		margin-bottom: 20px;
		margin-right: 0;
		text-align: center;
	}
	.l-catPageCategory {
		width: 50%;
	}
	.c-filter {
		margin: -20px auto 30px;
	}
	
	.for-print {
		display: none;
	}
	.c-page-title--link {
		font-size: 20px;
	}
	.c-product__top {
		display: block;
	}
	.l-product__gallery {
		width: 100%;
		max-width: 290px;
		margin: 0 auto 25px;
		height: auto;
	}
	.l-productInfo {
		width: 100%;
		.c-pin {
			padding-left: 0;
		}
	}
	.c-productInfo {
		margin-left: -15px;
		margin-right: -15px;
		width: calc(100% + 30px);
	}
	.c-product__galleryNav {
		width: 200px;
	}
	.c-product__gallery .c-slide {
		height: 320px;
	}
	.w-product__gallery {
		height: 325px;
	}
	.navN ul {
		justify-content: center;
	}
	
	.c-productTable .cart-foot {
		display: block;
		.c-lastUpd {
			text-align: center;
			margin-top: 30px;
		}
	}
	.l-tabsAdp {
		display: block;
	}
	.l-tabs {
		display: none;
	}
	.l-lkNav {
		margin-top: 30px;
	}
	.c-lkBlc--settings {
		.field {
			display: block;
			width: 100%;
		}
		.lbl {
			margin-bottom: 7px;
		}
	}
	.c-news__item {
		display: block;
	}
	.c-news__img {
		margin-right: 0;
		margin-bottom: 20px;
		max-width: 290px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.c-newsPage__img {
		float: none;
	}
	.arcticmodal-container_i2 {
		padding: 0;
	}
	.field--modal {
		display: block;
		.pcheck {
			margin-bottom: 10px;
		}
	}
}
@media all and (min-width: 320px) and (max-width: 479px) {
	.box-modal {
		width: 100%;
		max-width: 440px;
	}
}



@media all and (min-width: 320px) and (max-width: 991px) {
	.c-sidebarNav {
		display: none;
	}
	.c-sidebarNavAdp {
		display: block;
	}
	.c-cartTable__foot {
		height: auto;
		padding: 10px;
		display: block;
	}
	.c-cartTable__foot-links {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		margin-bottom: 15px;
		a {
			margin-bottom: 7px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		a:first-child {
			padding-left: 0;
			&:before {
				display: none;
			}
		}
		.sep {
			display: none;
		}
	}
	.c-cartTable__foot-sum {
		flex-shrink: 0;
		padding-right: 0;
		font-size: 14px;
		text-align: right;
	}
	.c-title {
		font-size: 18px;
	}
	.c-title--blc {
		left: 21px;
	}
	.c-cartBlc--shipping .field {
		display: block;
		width: 100%;
		.lbl {
			width: 100%;
			padding: 0;
			margin-bottom: 13px;
		}
	}
	.pcheck__row {
		display: block;
	}
	.pcheck__item {
		margin-right: 0;
		margin-bottom: 17px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.l-shippingTable {
		width: 100%;
	}
	.c-cartBlc--contens .field {
		display: block;
		width: 100%;
		.lbl {
			width: 100%;
			padding: 0;
			margin-bottom: 13px;
		}
	}
	.c-cartBlc__bottom {
		padding: 30px 20px 0;
	}
}