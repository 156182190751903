.lbl {
	font-size: 14px;
	color: #316ab2;
	font-weight: bold;
	font-family: $sans-font;
	margin-bottom: 0;
}

.inp {
	width: 100%;
	background: #f1f4f7;
	border: 1px solid #d4d9e0;
	height: 40px;
	outline: none;
	padding: 0 19px;
	font-size: 14px;
	color: #8a8b93;
	&:focus {color: #3e4144;}
	&::-webkit-input-placeholder       {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&::-moz-placeholder                {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&:-moz-placeholder                 {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&:-ms-input-placeholder            {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
	&:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
	&:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
	&:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
}

.fieldarea {
	align-items: flex-start !important;
	.lbl {
		margin-top: 13px;
	}
}

.field {
	position: relative;
}

.help-block {
	position: absolute;
	color: #de413b;
	font-size: 14px;
	margin: 0;
	width: 100%;
	left: calc(100% + 10px);
	max-width: 326px;
	font-family: "PT Sans", Arial, sans-serif;
}

.inp2 {
	width: 100%;
	background: #f1f4f7;
	border: 1px solid #d4d9e0;
	height: 100px;
	outline: none;
	padding: 10px 19px;
	font-size: 14px;
	color: #8a8b93;
	resize: none;
	&:focus {color: #3e4144;}
	&::-webkit-input-placeholder       {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&::-moz-placeholder                {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&:-moz-placeholder                 {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&:-ms-input-placeholder            {font-family: $sans-font; color: #505356; opacity: 1; transition: opacity 0.3s ease;}
	&:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
	&:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
	&:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
	&:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}
}

.captcha {
	margin: 20px 0;
	position: relative;
	display: flex;
	align-items: center; 
	.help-block {
		left: 314px;
	}
}